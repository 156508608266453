const toVideoPlayerOptions = require('toVideoPlayerOptions')
const YoutubeVideo = require('./youtubeVideo')
const webpage = document.querySelector('.webpage')

$(document).on('appReady', () => {
  document
    .querySelectorAll('.youtubeVideo')
    .forEach(el => {
      const parentModuleClass = el.parentNode.getAttribute('class')
      const options = toVideoPlayerOptions(parentModuleClass)

      new YoutubeVideo(el, options)
    })

  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      mutation.addedNodes.forEach(node => {
        if (node instanceof HTMLElement) {
          if (node.matches('.youtubeVideo-container')) YoutubeVideo.vitalize(node.parentNode)

          node
            .querySelectorAll('.youtubeVideo-container')
            .forEach(el => YoutubeVideo.vitalize(el.parentNode))
        }
      })
    })
  })

  observer.observe(webpage, { childList: true, subtree: true })
})
