import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'
import JSON_HEADERS from 'jsonHeaders'
import { Caption } from '../textNodes'

import { setHidden, setTermsAgreement } from './newPaybarActions'
import { detectPaybarFieldset } from './variants'

import CrossButton from '../crossButton/crossButton'
import NewPaybarNewCardCaption from './newPaybarNewCardCaption'
import NewPaybarCardSwitch from './newPaybarCardSwitch'
import NewPaybarCardIframe from './newPaybarCardIframe'
import NewPaybarPersonalFormWrapper from './newPaybarPersonalFormWrapper'
import { hasCompanyPurchaseSupport } from './helpers/companySubscriptionHelper'

import NewPaybarCompanyHowToInfo from './newPaybarCompanyHowToInfo'

import expandScreenToFlags from './expandScreenToFlags'
import NewPaybarTabs from './newPaybarTabs'
import getEmailsArrayFromString from './getEmailsArrayFromString'
import { FormCheckbox } from '../form'

const extractMeta = () => (window.location.search + '').replace(/^\?/, '')

class NewPaybarPersonalForm extends Component {
  constructor(props) {
    super(props)

    this.handleSubscription = this.handleSubscription.bind(this)
    this.handleTermsAgreementChange = this.handleTermsAgreementChange.bind(this)
  }

  get isChangingCard() {
    return this.props.currentPaymentMethod === 'newCard'
  }

  get isInternationalCard() {
    return this.props.currentPaymentMethod === 'internationalCard'
  }

  get wasChangingCard() {
    return ['newCard', 'existingCard', 'internationalCard'].includes(this.props.currentPaymentMethod)
  }

  componentDidUpdate() {
    this.mountOnApplication()
  }

  componentDidMount() {
    this.mountOnApplication()
  }

  mountOnApplication() {
    window.application.newPaybar.form = this
  }

  handleSubscription(method) {
    const data = this.data

    return fetch(this.props.subscriptionUrl, {
      ...this.headers,
      method: method || 'POST',
      body: JSON.stringify(data),
    })
      .then(this.handleResponse)
      .then(json => { return { ...json, ...data } })
  }

  handleResponse(res) {
    return res.ok ? res.json() : res.json().then(json => Promise.reject(json))
  }

  handleTermsAgreementChange({ value }) {
    this.props.setTermsAgreement(value, this.props.type)
  }

  get headers() {
    return {
      credentials: 'include',
      headers: { ...JSON_HEADERS, 'X-User-Token': this.props.accessToken },
    }
  }

  get data() {
    const reducer = (json, field) => {
      const fieldName = field.getAttribute('name')
      const isEmails = fieldName === 'emails'
      const isStudent = fieldName === 'isStudent'

      if (isStudent) return { [fieldName]: this.props.isStudent[this.props.type], ...json }

      return {
        [fieldName]: isEmails ? getEmailsArrayFromString(field.value) : field.value,
        ...json,
      }
    }

    const formData = [...this.el.querySelectorAll('[name]')].reduce(reducer, {})

    return {
      ...formData,
      type: this.props.type,
      coupon: this.props.coupon,
      meta: extractMeta(),
    }
  }

  get agreedToTermsText() {
    const { type, product, participantType } = this.props
    let suffix = type

    if (product === 'school' && type === 'company' && participantType === 'self') {
      suffix = 'companySelf'
    }

    return I18n.t(`newPaybar.termsCheckbox.${product}.${suffix}`)
  }

  render(props) {
    const isDisabled = props.isLoading || !props.isValid
    const isCompanyEdit = props.isSubscriptionScreen && props.type === 'company'
    const isSubscriptionEdit = isCompanyEdit && ['bookshelf', 'collecture'].includes(props.product)
    const isCompanyPaysByNewCard = props.type === 'company' && props.companyPaymentType === 'card' && !props.userCardNumber
    const isCompanyPaysByInvoice = props.type === 'company' && props.companyPaymentType === 'invoice'

    const className = cx('newPaybarPersonalForm',
      `is__${this.props.type}`,
      `is__${this.props.product}`,
      {
        is__disabled: isDisabled,
        is__loading: props.isLoading,
        is__subscriptionEdit: isSubscriptionEdit,
        has__userName: !!this.props.userName,
        has__userCard: !!this.props.userCardNumber && !this.props.currentPaymentMethod,
      })

    const NewPaybarPersonalFieldset = detectPaybarFieldset(props.product)
    const shouldDisplayFieldset = props.isPurchaseScreen || isCompanyEdit
    const shouldDisplayHowTo = hasCompanyPurchaseSupport(props.product) && props.type === 'company'

    const hasTerms = ['school', 'course'].includes(props.product)

    return (
      <NewPaybarPersonalFormWrapper
        formRef={ el => this.el = el }
        scrollableRef={ props.scrollableRef }>

        <NewPaybarTabs
          device="desktop laptop"
          type={ props.type }
          product={ props.product } />

        { shouldDisplayFieldset &&
          <div className={ className }>
            <CrossButton device="laptop desktop" onClick={ props.setHidden } />

            <div>{ this.props.children }</div>

            <NewPaybarPersonalFieldset
              type={ props.type }
              hasTerms={ hasTerms }
              isAgreedToTerms={ props.isAgreedToTerms }
              product={ props.product } />

            { !isCompanyEdit && !isCompanyPaysByInvoice &&
              <div className="newPaybarCardWrapper">
                { (this.props.cardIframeUrl || this.wasChangingCard) &&
                  <NewPaybarCardSwitch accessToken={ props.accessToken } />
                }

                { this.props.cardIframeUrl && !this.isInternationalCard &&
                    <NewPaybarCardIframe
                      type={ props.type }
                      src={ props.cardIframeUrl }
                      userCardNumber={ props.userCardNumber }
                      isCompanyPaysByNewCard={ isCompanyPaysByNewCard }
                      companyPaymentType={ props.companyPaymentType }
                      currentPaymentMethod={ props.currentPaymentMethod }
                      accessToken={ props.accessToken } />
                }

                { this.isChangingCard && <NewPaybarNewCardCaption /> }
              </div>
            }

            { shouldDisplayHowTo &&
              <NewPaybarCompanyHowToInfo />
            }

            { hasTerms &&
              <div className="newPaybarPersonalForm-checkboxes">
                <FormCheckbox
                  name="terms"
                  required
                  value={ props.isAgreedToTerms[props.type] }
                  onChange={ this.handleTermsAgreementChange }
                  text={ this.agreedToTermsText } />

                { props.product === 'school' && props.type === 'self' && props.enrollment === 'grant' &&
                  <Caption>{ I18n.t(`newPaybar.termsCheckboxCaption.${props.product}`) }</Caption>
                }
              </div>
            }
          </div>
        }
      </NewPaybarPersonalFormWrapper>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    prices: newPaybar.prices,
    type: newPaybar.type,
    product: newPaybar.product,
    coupon: newPaybar.prices.coupon,
    accessToken: newPaybar.accessToken,
    userName: newPaybar.userName,
    userCardNumber: newPaybar.userCardNumber,
    cardIframeUrl: newPaybar.cardIframeUrl,
    currentPaymentMethod: newPaybar.currentPaymentMethod,
    isLoading: newPaybar.isLoading,
    isValid: newPaybar.isValid,
    isAgreedToTerms: newPaybar.isAgreedToTerms,
    enrollment: newPaybar.enrollment,
    companyPaymentType: newPaybar.companyPaymentType,
    participantType: newPaybar.participantType,
    isStudent: newPaybar.isStudent,
    ...expandScreenToFlags(newPaybar.screen),
  }
}

const mapDispatchToProps = { setHidden, setTermsAgreement }

export default connect(mapStateToProps, mapDispatchToProps)(NewPaybarPersonalForm)
