import JSON_HEADERS from 'jsonHeaders'

module.exports = {
  verifySubscription: ({ email, mailingList, leadMagnetId }) => {
    const url = `/mailing_lists/${mailingList}/opt_ins/?email=${email}&leadMagnetId=${leadMagnetId}`
    const params = {
      method: 'GET',
      credentials: 'include',
      headers: JSON_HEADERS,
    }

    return fetch(url, params)
      .then(res => {
        if (!res.ok) throw Error(res.statusText)

        return res
      })
  },
}
