import { Component } from 'preact'

import FormFieldsStorage from './formFieldsStorage'
import { hasCompanyPurchaseSupport } from './helpers/companySubscriptionHelper'

export default class NewPaybarPersonalFieldsetBase extends Component {
  constructor(props) {
    super(props)

    this.isOnMobile = window.application.isOnMobile

    this.fieldRefs = {}
    this.rememberedFields = {}
    this.formFieldsStorage = new FormFieldsStorage()

    this.onInputValidityChange = this.onInputValidityChange.bind(this)
    this.onInput = this.onInput.bind(this)
    this.setCardCompanyPayment = this.setCardCompanyPayment.bind(this)
    this.setInvoiceCompanyPayment = this.setInvoiceCompanyPayment.bind(this)
    this.bindEl = (el) => this.el = el
  }

  isFieldEmpty(field) {
    if (!field) return true

    const value = field.state.value
    const isArray = Array.isArray(value)

    return isArray ? !value.length : !(value || '').trim().length
  }

  get isValid() {
    if (this.props.hasTerms && !this.isAgreedToTerms) return false

    const isAllFieldsValid = this.validityMaps[this.props.type]
      .every(field => this.validityStates[field])

    if (this.props.type === 'company' && !hasCompanyPurchaseSupport(this.props.product)) {
      return isAllFieldsValid && (this.isCompanyChanged || this.isEmailsChanged)
    }

    return isAllFieldsValid
  }

  get isAgreedToTerms() {
    return this.props.isAgreedToTerms[this.props.type]
  }

  saveAgreedToTerms() {
    this.rememberField({ name: 'isAgreedToTerms', value: this.props.isAgreedToTerms })
  }

  saveIsStudent() {
    this.rememberField({ name: 'isStudent', value: this.props.isStudent })
  }

  get isEmpty() {
    return this.fields.every(this.isFieldEmpty)
  }

  get isCompanyChanged() {
    const companyField = this.fields.find(field => field.props.name === 'company')
    if (!companyField) return false

    return this.props.company !== companyField.state.value
  }

  get isEmailsChanged() {
    const emailsTextarea = this.fields.find(field => field.props.name === 'emails')
    if (!emailsTextarea) return false

    const emailsDefault = this.props.subscription.employee_emails
    const emailsCurrent = emailsTextarea.validEmails

    if (emailsDefault.length !== emailsCurrent.length) return true

    emailsDefault.sort()
    emailsCurrent.sort()

    return emailsCurrent.some((email, index) => email !== emailsDefault[index])
  }

  get productId() {
    return this.props.subscription.product_id
  }

  componentWillMount() {
    if (this.props.shouldClearOnReload) {
      this.clearRememberedFieldsOnReload()
    } else {
      this.getRememberedFieldsFromStorage()
    }
  }

  componentDidMount() {
    this.props.setFormValidity({ isValid: this.isValid })
    this.focusFirstBlankInput()
  }

  componentDidUpdate(prevProps) {
    const changeType = prevProps.type !== this.props.type
    const showPaybar = !prevProps.isVisible && this.props.isVisible

    this.props.setFormValidity({ isValid: this.isValid })

    if (changeType || showPaybar) this.focusFirstBlankInput()

    this.saveAgreedToTerms()
    this.saveIsStudent()
  }

  onInput({ name, value }) {
    if (this.isEmpty) {
      this.props.removeDirtyForm()
    } else {
      this.props.addDirtyForm()
    }

    this.rememberField({ name, value })
  }

  onInputValidityChange(inputName, validity) {
    this.validityStates[inputName] = validity

    this.props.setFormValidity({ isValid: this.isValid })
  }

  remember(name) {
    this.rememberField({ name, value: this.props[name] })
  }

  rememberField({ name, value }) {
    this.rememberedFields[name] = value
    this.saveRememberedFieldsToStorage()
  }

  focusFirstBlankInput() {
    if (this.isOnMobile) return

    const input = this.el.querySelector('input:invalid')

    // HACK: Задержка, иначе Сафари ускроллит страницу, пытаясь фокусить не успевшую выехать форму
    if (input) setTimeout(input.focus.bind(input), 150)
  }

  saveRememberedFieldsToStorage() {
    this.formFieldsStorage.save(this.rememberedFields)
  }

  getRememberedFieldsFromStorage() {
    this.rememberedFields = this.formFieldsStorage.get()
  }

  get fields() {
    return Object.keys(this.fieldRefs)
      .map(key => this.fieldRefs[key])
      .filter(field => field)
  }

  clearRememberedFieldsOnReload() {
    this.formFieldsStorage.clear()
  }

  setCardCompanyPayment() {
    this.props.setCompanyPaymentType('card')
  }

  setInvoiceCompanyPayment() {
    this.props.setCompanyPaymentType('invoice')
  }
}
