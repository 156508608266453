import { combineReducers } from 'redux'
import isSubscribed from './isSubscribed'

import {
  revertStateToInitial,
  getTypeToOpen,
  getScreenToOpen,
  getBasementItemToOpen,
  getGiftScreen,
  removeDirtyForm,
  addDirtyForm,
  isValid,
  setScreenSuccess,
} from './reducerHelpers'
import isSubscriptionCancelled from './isSubscriptionCancelled'
import getCompanyTypeScreen from './helpers/getCompanyTypeScreen'

let statePriorToUserSwitch = {}

const switchToInitialScreen = (state) => {
  if (state.isUserAuthenticated) statePriorToUserSwitch = { ...state }

  return revertStateToInitial(state)
}

const newPaybar = (state = {}, action) => {
  switch (action.type) {
    case 'SET_VISIBLE':
      return {
        ...state,
        type: getTypeToOpen(state),
        screen: getScreenToOpen(state),
        activeBasementItem: getTypeToOpen(state) === 'self' ? getBasementItemToOpen(state) : undefined,
        isVisible: true,
      }

    case 'SET_HIDDEN':
      if (state.isLoading) return state

      let newScreen = state.screen
      if (state.screen === 'success') {
        newScreen = isSubscribed(state) ? 'subscription' : 'purchase'
      }

      return {
        ...state,
        isVisible: false,
        screen: newScreen,
        noticeType: !state.isUserAuthenticated && state.hasSubscribedSelf ? 'emailSent' : state.noticeType,
        activeBasementItem: undefined,
        isLoading: false,
      }

    case 'SET_SCREEN':
      return { ...state, screen: action.screen }

    case 'SWITCH_TO_INITIAL_SCREEN':
      if (state.isLoading) return state

      return switchToInitialScreen(state)

    case 'SUBSCRIBE_SOMEONE_ELSE':
      if (!state.isUserAuthenticated) return switchToInitialScreen(state)

      return {
        ...state,
        screen: 'purchase',
        type: state.type === 'company' ? 'company' : 'gift',
        participantType: state.type === 'company' ? 'other' : 'self',
        paymentType: 'oneTime',
        activeBasementItem: undefined,
      }

    case 'SET_USER':
      statePriorToUserSwitch = {}

      return {
        ...state,
        userEmail: action.email,
        accessToken: action.accessToken,
        cardIframeUrl: action.cardIframeUrl,
        screen: 'purchase',
        presenteeEmail: null,
        hasSubscribedSelf: false,
      }

    case 'REVERT_PREVIOUS_USER_STATE':
      return { ...statePriorToUserSwitch }

    case 'SET_USER_EMAIL_NOTICE':
      return {
        ...state,
        userEmailNotice: action.notice,
        userEmail: action.email,
        accessToken: null,
      }

    case 'SET_LOADING':
      return { ...state, isLoading: true, noticeType: null }

    case 'SET_BASEMENT_LOADING':
      return { ...state, isBasementLoading: true }

    case 'SET_FORM_VALIDITY':
      if (action.isValid !== state.isFormValid) {
        return {
          ...state,
          isFormValid: action.isValid,
          isValid: isValid({ ...state, isFormValid: action.isValid }),
        }
      }

      return state

    case 'SET_CARD_FORM_VALIDITY':
      if (action.isValid !== state.isCardFormValid) {
        return {
          ...state,
          isCardFormValid: action.isValid,
          isValid: isValid({ ...state, isCardFormValid: action.isValid }),
        }
      }

      return state

    case 'SET_PAYMENT_FAILED':
      return { ...state, noticeType: 'paymentFailed', isLoading: false }

    case 'SET_PAYMENT_FAILED_AMOUNT_EXCEED':
      return { ...state, noticeType: 'paymentFailedAmountExceed', isLoading: false }

    case 'SET_PAYMENT_FAILED_FOREIGN_ISSUER_BLOCKED_CARD':
      return { ...state, noticeType: 'paymentFailedForeignIssuerBlockedCard', isLoading: false }

    case 'SET_PAYMENT_FAILED_FOREIGN_ISSUER_BLOCKED_CARD_COMPLETELY':
      return { ...state, noticeType: 'paymentFailedForeignIssuerBlockedCardCompletely', isLoading: false }

    case 'SET_SUBSCRIPTION_UPDATE_FAILED':
      return { ...state, noticeType: 'subscriptionUpdateFailed', isLoading: false }

    case 'SET_SUBSCRIPTION_CANCELLATION_FAILED':
      return { ...state, noticeType: 'subscriptionCancellationFailed', isBasementLoading: false }

    case 'SET_SUBSCRIPTION_CANCELLATION_REVERT_FAILED':
      return { ...state, noticeType: 'subscriptionCancellationRevertFailed', isBasementLoading: false }

    case 'CHANGE_PAYMENT_METHOD':
      if (state.isLoading) return state

      return {
        ...state,
        currentPaymentMethod: action.paymentMethod,
        isValid: isValid({ ...state, currentPaymentMethod: action.paymentMethod }),
      }

    case 'SET_TYPE_SELF':
      if (state.isLoading) return state

      return {
        ...state,
        type: 'self',
        userEmailNotice: null,
        screen: isSubscribed(state) || isSubscriptionCancelled(state.subscription) ? 'subscription' : state.screen,
        activeBasementItem: getBasementItemToOpen(state),
      }

    case 'SET_TYPE_GIFT':
      if (state.isLoading) return state

      return {
        ...state,
        type: 'gift',
        paymentType: 'oneTime',
        userEmailNotice: null,
        screen: getGiftScreen(state),
        activeBasementItem: undefined,
      }

    case 'SET_TYPE_UPGRADE':
      if (state.isLoading) return state

      return {
        ...state,
        type: 'upgrade',
        userEmailNotice: null,
        screen: 'purchase',
      }

    case 'SET_TYPE_COMPANY':
      if (state.isLoading) return state

      return {
        ...state,
        type: 'company',
        userEmailNotice: null,
        activeBasementItem: undefined,
        screen: getCompanyTypeScreen({
          product: state.product,
          isUserAuthenticated: state.isUserAuthenticated,
          subscription: state.subscription,
          screen: state.screen,
        }),
      }

    case 'SET_SCREEN_SUCCESS':
      return setScreenSuccess(state, action)

    case 'SET_FORM_HAS_CONTENT':
      return { ...state, hasFormContent: action.hasFormContent }

    case 'SET_VISIBLE_GIFT':
      if (state.isLoading) return state

      return {
        ...state,
        type: 'gift',
        paymentType: 'oneTime',
        userEmailNotice: null,
        screen: getGiftScreen(state),
        isVisible: true,
      }

    case 'ADD_DIRTY_FORM':
      return {
        ...state,
        dirtyForms: addDirtyForm(state),
      }

    case 'REMOVE_DIRTY_FORM':
      return {
        ...state,
        dirtyForms: removeDirtyForm(state),
      }

    case 'SET_UPDATED_SUBSCRIPTION':
      return {
        ...state,
        isLoading: false,
        activeBasementItem: getBasementItemToOpen(action),
        hasUpdatedSubscription: true,
        subscription: {
          ...state.subscription,
          ...action.subscription,
        },
      }

    case 'SET_CANCELLED_SUBSCRIPTION':
      return {
        ...state,
        isBasementLoading: false,
        type: 'self',
        screen: 'subscription',
        activeBasementItem: 'lastChance',
        subscription: {
          ...state.subscription,
          status: 'cancelled',
          retention_token_code: action.token,
          retention_token_valid_until: action.tokenValidUntil,
        },
        lastSubscription: state.subscription,
      }

    case 'REVERT_CANCELLED_SUBSCRIPTION':
      return {
        ...state,
        isBasementLoading: false,
        type: 'self',
        screen: 'subscription',
        activeBasementItem: undefined,
        subscription: {
          ...state.lastSubscription,
          status: 'active',
          retention_token_code: undefined,
          retention_token_valid_until: state.subscription.retention_token_valid_until,
        },
        lastSubscription: undefined,
      }

    case 'SET_UPDATED_PRICING':
      return {
        ...state,
        prices: {
          ...state.prices,
          ...action.prices,
        },
      }

    case 'SET_PAYMENT_TYPE':
      return {
        ...state,
        paymentType: action.paymentType,
      }

    case 'SET_COMPANY_PAYMENT_TYPE':
      return {
        ...state,
        companyPaymentType: action.companyPaymentType,
      }

    case 'SET_ENROLLMENT':
      return {
        ...state,
        enrollment: action.enrollment,
      }

    case 'SET_PARTICIPANT_TYPE':
      return {
        ...state,
        participantType: action.participantType,
      }

    case 'SET_PARTICIPANT_STUDENT':
      return {
        ...state,
        isStudent: {
          ...state.isStudent,
          [action.formType]: action.isStudent,
        },
      }

    case 'SET_DOC_FLOW_TYPE':
      return {
        ...state,
        docFlowType: action.docFlowType,
      }

    case 'SET_TERMS_AGREEMENT':
      return {
        ...state,
        isAgreedToTerms: {
          ...state.isAgreedToTerms,
          [action.formType]: action.isAgreedToTerms,
        },
      }

    case 'CHANGE_BASEMENT_ITEM':
      return {
        ...state,
        activeBasementItem: action.item,
      }

    case 'CLOSE_BASEMENT':
      return {
        ...state,
        activeBasementItem: undefined,
      }

    default:
      return state
  }
}

const newPaybarReducer = combineReducers({ newPaybar })

export default newPaybarReducer
