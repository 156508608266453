import { Component } from 'preact'
import TextareaAutosize from 'react-textarea-autosize'
import cx from 'classnames'
import AUTH_HEADERS from 'authHeaders'

import isValidName from 'lib/users/isValidName'

import { Form } from '../form'
import { Cols } from '../cols'
import { Caption } from '../textNodes'

import { AttachmentIconWithHover } from '../icons'
import DeleteButton from '../deleteButton/deleteButton'

import CommentFormAuthorRow from '../commentForm/commentFormAuthorRow'
import CommentFormAuthorFieldset from '../commentAtoms/commentFormAuthorFieldset'

export default class CommentEditable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: props.comment.authorName,
      email: props.comment.authorEmail,
      company: props.comment.authorCompany,
      jobTitle: props.comment.authorJobTitle,
      body: props.comment.body,
      status: props.comment.status,
      isValid: false,
      isSent: false,
      hasError: false,
      attachmentUrl: props.comment.imageUrl,
      isAttachmentDeleted: false,
    }

    this.isCommentAuthorAuthenticated = !!props.comment.sovietUsername
    this.isEditable = !this.isCommentAuthorAuthenticated || !isValidName(props.comment.name)

    this.validityStates = this.isCommentAuthorAuthenticated ? {} : { name: false, email: false }

    this.handleInput = this.handleInput.bind(this)
    this.handleAuthorFieldsetEdit = this.handleAuthorFieldsetEdit.bind(this)
    this.displayAttachment = this.displayAttachment.bind(this)
    this.deleteAttachment = this.deleteAttachment.bind(this)
    this.submit = this.submit.bind(this)
    this.onInputValidityChange = this.onInputValidityChange.bind(this)
    this.handleFetchError = this.handleFetchError.bind(this)
    this.finishEditing = this.finishEditing.bind(this)
  }

  get isValid() {
    return this.state.body &&
      this.state.body.trim() &&
      Object.keys(this.validityStates).every(field => this.validityStates[field])
  }

  get isDisabled() {
    return !this.isValid || this.state.isLoading
  }

  handleInput(e) {
    this.setState({
      body: e.target.value,
      isValid: this.isValid,
      hasError: false,
    })
  }

  handleAuthorFieldsetEdit({ name = this.state.name, email = this.state.email }) {
    this.setState({ name, email })
  }

  displayAttachment() {
    if (!this.fileInput || !this.fileInput.files) return

    const { files } = this.fileInput

    const rawFile = files[0]

    this.readAttachment(rawFile)
      .then(dataUrl => this.setState({ attachmentUrl: dataUrl }))
  }

  deleteAttachment() {
    if (this.fileInput) this.fileInput.value = ''
    this.setState({ attachmentUrl: '', isAttachmentDeleted: true })
  }

  readAttachment(rawFile) {
    if (!rawFile) return null

    return new Promise(resolve => {
      const reader = new FileReader()
      reader.onload = (e) => resolve(e.target.result)
      reader.readAsDataURL(rawFile)
    })
  }

  onInputValidityChange(inputName, validity) {
    this.validityStates[inputName] = validity

    this.setState({ isValid: this.isValid, hasError: false })
  }

  submit(data) {
    this.setState({ isLoading: true, hasError: false })

    this.submitToServer(data)
  }

  submitToServer(data) {
    const subject = encodeURIComponent(this.props.commentableSubject)
    const url = `/commentables/${subject}/comments/${this.props.comment.id}/`
    const headers = { ...AUTH_HEADERS, Accept: 'application/json' }
    const body = this.toFormData(data)

    fetch(url, { method: 'PATCH', headers, body })
      .then(res => {
        if (!res.ok) throw Error(res.statusText)

        this.handleSuccessfullySentComment()
      })
      .catch(this.handleFetchError)
  }

  toFormData(data) {
    let formData = new FormData()

    Object.keys(data).forEach(key => {
      const authorField = ['email', 'name', 'company', 'job_title']
      const formKey = (authorField.includes(key) ? `author_${key}` : key)
      formData.append(`comment[${formKey}]`, data[key])
    })
    formData.append('comment[status]', this.state.status)

    if (this.fileInput && this.fileInput.files && this.fileInput.files.length) {
      formData.append('comment_image', this.fileInput.files[0])
    }
    if (this.state.isAttachmentDeleted) formData.append('comment_image', '')

    return formData
  }

  handleSuccessfullySentComment() {
    this.setState({ isSent: true, body: '' })
    this.finishEditing()
    this.props.onAfterEdit()
  }

  handleFetchError() {
    this.setState({ hasError: true, isLoading: false })
  }

  finishEditing() {
    this.props.toggleEditable(false)
  }

  render(props) {
    const comment = props.comment
    const submitAttrs = { disabled: this.isDisabled ? 'disabled' : '' }
    const className = cx('commentForm', { is__loading: this.state.isLoading })

    return (
      <div className="module comment is__edit" id={ `comment-${comment.id}` }>
        <Cols className="is__transposeOnMobile" divisionProportions="11:1:4">
          <Form className={ className } onSubmit={ this.submit }>
            <CommentFormAuthorRow
              commentId={ comment.id }
              author={{ name: comment.authorName, email: comment.authorEmail, avatarUrl: comment.authorAvatarUrl }}
              isAuthenticated={ this.isCommentAuthorAuthenticated }
            />

            { this.isEditable &&
              <CommentFormAuthorFieldset
                author={ this.state }
                onEdit={ this.handleAuthorFieldsetEdit }
                onValidityChange={ this.onInputValidityChange }
                disableEmailValidation
              />
            }

            <div className="commentForm-textWrapper">
              <TextareaAutosize
                name="body"
                className="commentForm-text"
                placeholder="Комментарий"
                value={ this.state.body }
                onInput={ this.handleInput } />
            </div>

            { this.state.attachmentUrl &&
              <div
                className="commentForm-attachment is__position"
                style={ { backgroundImage: `url(${this.state.attachmentUrl})` } }
              >
                <DeleteButton onClick={ this.deleteAttachment }/>
              </div>
            }

            <footer className="commentForm-footer">
              <span className="commentForm-submitWrapper">
                <button
                  className="button is__enlargedOnMobiles"
                  type="submit"
                  { ...submitAttrs }>
                  Сохранить
                </button>
              </span>

              <button
                className="commentForm-cancelButton"
                onClick={ this.finishEditing }
              >
                Отменить
              </button>

              { this.state.hasError &&
                <div className="commentForm-error">
                  <Caption className="error">Ошибка отправки. Попробуйте ещё раз</Caption>
                </div>
              }

              <div className="commentForm-attach attach">
                <AttachmentIconWithHover />
                <input
                  ref={ el => this.fileInput = el }
                  type="file"
                  name="attachment"
                  onChange={ this.displayAttachment }
                  accept="image/jpeg,image/png,image/gif" />
              </div>
            </footer>
          </Form>
          <div />
          <div />
        </Cols>
      </div>
    )
  }
}
