import { Component } from 'preact'
import cx from 'classnames'

import { schoolEnrollMarksSpacer as config } from './schoolSpacersConfig'
import { fetchSchoolData, getSchoolLink } from './schoolSpacerUtils'
import { getStoredEmail, subscribeUser } from '../subscriptionForm/emailUtils'

import { TextNode } from '../textNodes'
import LinkWithArrow from '../linkWithArrow'
import SchoolSpacerTitle from './schoolSpacerTitle'
import EmailForm from '../emailForm'
import SchoolSpacerIcon from './schoolSpacerIcon'

const ICONS_MAP = {
  designers: 'designersChaletIcon',
  editors: 'editorsMushroomIcon',
  managers: 'managersLegoIcon',
}

const EVENT_ID = 1

export default class SchoolEnrollMarksSpacer extends Component {
  constructor(props) {
    super(props)

    const { email, isSubscribed } = getStoredEmail(props.eventType)

    this.state = { email, isSubscribed }

    this.isFull = props.size === 'full'
    this.isHalf = props.size === 'half'
    this.schoolLink = getSchoolLink(props.schoolKey)

    this.subscribe = this.subscribe.bind(this)
  }

  subscribe({ email }) {
    return subscribeUser({ email, eventType: this.props.eventType, eventId: EVENT_ID })
      .then(isSubscribed => this.setState({ isSubscribed }))
  }

  render(props) {
    const classes = cx(
      'schoolEnrollMarksSpacer',
      {
        is__full: this.isFull,
        is__half: this.isHalf,
        [`is__${props.schoolKey}`]: props.schoolKey,
      }
    )

    return (
      <div className={ classes }>
        <div className="device laptop desktop">
          <div className="schoolEnrollMarksSpacer-cols">
            <SchoolSpacerIcon iconKey={ ICONS_MAP[props.schoolKey] } href={ this.schoolLink } />
            <div className="schoolEnrollMarksSpacer-text">
              <SchoolSpacerTitle href={ this.schoolLink } title={ config.title[props.schoolKey] } />
              <div className="schoolEnrollMarksSpacer-undertitle">
                <div className="schoolEnrollMarksSpacer-about">
                  <TextNode>
                    <span
                      dangerouslySetInnerHTML={ { __html: config.text } }
                    /> <LinkWithArrow href={ this.schoolLink } text="Программа" isInline isDoubleHover />
                  </TextNode>
                </div>
                <EmailForm
                  email={ this.state.email }
                  onSubmit={ this.subscribe }
                  className="schoolEnrollMarksSpacer-form"
                  isSubscribed={ this.state.isSubscribed }
                  thinButton
                />
              </div>
            </div>
          </div>
        </div>

        <div className="device mobile">
          <div className="schoolEnrollOpenSpacer-cols">
            <SchoolSpacerIcon iconKey={ ICONS_MAP[props.schoolKey] } href={ this.schoolLink } />
            <SchoolSpacerTitle href={ this.schoolLink } title={ config.title[props.schoolKey] } />
          </div>
          <TextNode>
            <span
              dangerouslySetInnerHTML={ { __html: config.text } }
            /> <LinkWithArrow href={ this.schoolLink } text="Программа" isInline isDoubleHover />
          </TextNode>
          <EmailForm
            email={ this.state.email }
            onSubmit={ this.subscribe }
            className="schoolEnrollMarksSpacer-form"
            isSubscribed={ this.state.isSubscribed }
            thinButton
          />
        </div>
      </div>
    )
  }
}
