import { Component } from 'preact'
import cx from 'classnames'

import { Heading3 } from '../textNodes'

export default class NewPaybarDocFlowTypeSwitch extends Component {
  render() {
    const { docFlowType, onSetElectronic, onSetPaper } = this.props
    const isElectronic = docFlowType === 'electronic'

    const switcherItems = [
      {
        title: I18n.t('newPaybar.companyForm.docFlowTypeSwitch.electronic'),
        isActive: isElectronic,
        onClick: onSetElectronic,
      },
      {
        title: I18n.t('newPaybar.companyForm.docFlowTypeSwitch.paper'),
        isActive: !isElectronic,
        onClick: onSetPaper,
      },
    ]

    return (
      <div className="newPaybarSwitch">
        <Heading3 className="newPaybarSwitch-heading">
          { I18n.t('newPaybar.companyForm.docFlowType') }
        </Heading3>
        <div className="newPaybarSwitch-selector">
          { switcherItems.map(({ title: __html, isActive, onClick }) => (
          <span
            className={ cx('newPaybarSwitch-selectorItem', { is__active: isActive }) }
            onClick={ onClick }
            dangerouslySetInnerHTML={{ __html }} />
          ))}
        </div>
      </div>
    )
  }
}
