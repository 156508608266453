const { setAlpha } = require('../colorHelper')
const petrovich = require('petrovich')
const buildAuthorAvatarUrl = (filename) => `/assets/images/soviets/authors/dot-${filename}?v=11`

let authors = {
  'А. Г.': {
    authorBureauId: '388',
    authorAvatarUrl: buildAuthorAvatarUrl('gorbunov.png'),
    authorArchiveUrl: 'ag',
    authorCaption: 'Арт-директор и автор учебных программ бюро',
    authorEmails: ['artgorbunov@artgorbunov.ru'],
    keyColor: 'rgb(137, 25, 43)',
    keyUnderlineColor: 'rgba(137, 25, 43, .14)',
    bgColor: '#fff5d3',
    weekDay: 'mo',
  },
  'Илья Бирман': {
    authorBureauId: '570',
    authorAvatarUrl: buildAuthorAvatarUrl('birman.png'),
    authorArchiveUrl: 'ilyabirman',
    authorCaption: 'Арт‑директор и соавтор курса «Пользовательский интерфейс и представление информации»',
    authorEmails: ['ilyabirman@artgorbunov.ru', 'ilyabirman@ilyabirman.ru'],
    keyColor: 'rgb(17, 97, 150)',
    keyUnderlineColor: 'rgba(17, 97, 150, .14)',
    bgColor: '#dcf1ff',
    weekDay: 'tu',
  },
  'Константин Мозговой': {
    authorBureauId: '32019',
    authorAvatarUrl: buildAuthorAvatarUrl('mozgovoy.png'),
    authorArchiveUrl: 'mozgovoy',
    authorCaption: 'Дизайнер бюро',
    authorEmails: ['visualrock@yandex.ru'],
    keyColor: 'rgb(122, 48, 207)',
    keyUnderlineColor: 'rgba(122, 48, 207, .14)',
    bgColor: '#d1ffca',
    weekDay: '',
  },
  'Михаил Нозик': {
    authorBureauId: '25146',
    authorAvatarUrl: buildAuthorAvatarUrl('nozik.png'),
    authorArchiveUrl: 'nozik',
    authorCaption: 'Арт-директор и автор курса «Типографика и вёрстка»',
    authorEmails: ['nozik.m@gmail.com'],
    keyColor: '#a600ba',
    keyUnderlineColor: 'rgba(166, 0, 186, .14)',
    bgColor: '#ffdffd',
    weekDay: 'we',
  },
  'Василий Половнёв': {
    authorBureauId: '31288',
    authorAvatarUrl: buildAuthorAvatarUrl('polovnyov.png'),
    authorArchiveUrl: 'polovnyov',
    authorCaption: 'Технический директор бюро',
    authorEmails: ['vasily@polovnyov.ru'],
    authorTemplate: '+sidenote({ mod: \'footnote\' }) P. S. Это был совет о веб-разработке. Хотите знать всё о коде, тестах, фронтенд-разработке, цеэсэсе, яваскрипте, рельсах и джейде? <a href=\'/soviet/ask/\'>Присылайте вопросы</a>.',
    keyColor: 'rgb(134, 36, 26)',
    keyUnderlineColor: 'rgba(134, 36, 26, .14)',
    bgColor: '#ffe9e1',
    weekDay: 'th',
  },
  'Игорь Петров': {
    authorBureauId: '1385493',
    authorAvatarUrl: buildAuthorAvatarUrl('petrov.png'),
    authorArchiveUrl: 'petrov',
    authorCaption: 'Разработчик, преподаватель Школы бюро',
    authorEmails: ['petrov@bureau.ru'],
    authorTemplate: '+sidenote({ mod: \'footnote\' }) P. S. Это был совет о веб-разработке. Хотите знать всё о коде, тестах, фронтенд-разработке, цеэсэсе, яваскрипте, рельсах и джейде? <a href=\'/soviet/ask/\'>Присылайте вопросы</a>.',
    keyColor: 'rgb(5, 80, 255)',
    keyUnderlineColor: 'rgba(5, 80, 255, .14)',
    bgColor: '#8fd6f5',
    weekDay: 'th',
  },
  'Руст Кулматов': {
    authorBureauId: '27092',
    authorAvatarUrl: buildAuthorAvatarUrl('kulmatov.png'),
    authorArchiveUrl: 'kulmatov',
    authorCaption: 'Фронтенд-разработчик, преподаватель, соавтор движка электронных книг бюро',
    authorEmails: ['iam@stfoo.ru'],
    keyColor: 'rgb(31, 147, 96)',
    keyUnderlineColor: 'rgba(31, 147, 96, .14)',
    bgColor: '#d2fff0',
    weekDay: '',
  },
  'Юрий Мазурский': {
    authorBureauId: '34617',
    authorAvatarUrl: buildAuthorAvatarUrl('mazursky.png'),
    authorArchiveUrl: 'mazursky',
    authorCaption: 'Разработчик и дизайнер',
    authorEmails: ['ymazursky@artgorbunov.ru', 'colo3@yandex.ru'],
    keyColor: 'rgb(229, 87, 37)',
    keyUnderlineColor: 'rgba(229, 87, 37, .14)',
    bgColor: '#ffffa8',
    weekDay: '',
  },
  'Фёдор Борщёв': {
    authorBureauId: '1397628',
    authorAvatarUrl: buildAuthorAvatarUrl('borshev.png'),
    authorArchiveUrl: 'borschyov',
    authorCaption: 'Программист, стартапер, <span class="smallcapitals">ИТ</span>-консультант',
    authorEmails: ['fedor@borshev.com'],
    keyColor: 'rgb(8, 66, 158)',
    keyUnderlineColor: 'rgba(8, 66, 158, .14)',
    bgColor: '#7bd7fd',
    weekDay: '',
  },
  Рецепты: {
    authorBureauId: '1460614',
    authorAvatarUrl: buildAuthorAvatarUrl('recipes.png'),
    authorArchiveUrl: 'recipes',
    authorCaption: 'Пошаговые инструкции создания анимаций, трёхмерных картинок и других красивых штук',
    authorEmails: ['thedesignrecipes@gmail.com'],
    keyColor: 'rgb(66, 135, 245)',
    keyUnderlineColor: 'rgba(66, 135, 245, .14)',
    bgColor: '#e1e1e6',
    weekDay: 'we',
  },
  'Илья Синельников': {
    authorBureauId: '828',
    authorAvatarUrl: buildAuthorAvatarUrl('sinelnikov.png'),
    authorArchiveUrl: 'sinelnikov',
    authorCaption: 'Предприниматель, стартапер, преподаватель',
    authorEmails: ['is@artgorbunov.ru', 'is@dapplegrey.ru', 'kleverr@gmail.com'],
    keyColor: 'rgb(103, 72, 141)',
    keyUnderlineColor: 'rgba(103, 72, 141, .14)',
    bgColor: '#f2e6fd',
    weekDay: 'fr',
  },
  'Аня Данилова': {
    authorBureauId: '33210',
    authorAvatarUrl: buildAuthorAvatarUrl('danilova.png'),
    authorArchiveUrl: 'danilova',
    authorCaption: 'Шрифтовик бюро',
    authorEmails: ['danilova@artgorbunov.ru'],
    keyColor: 'rgb(227, 0, 22)',
    keyUnderlineColor: 'rgba(227, 0, 22, .14)',
    bgColor: '#f5f5f5',
    weekDay: '',
  },
  'Сергей Чикин': {
    authorBureauId: '1665',
    authorAvatarUrl: buildAuthorAvatarUrl('chikin.png'),
    authorArchiveUrl: 'kolpakov-chikin',
    authorCaption: 'Дизайнер-иконист, автор курса «Пиктограммы и системы знаков»',
    authorEmails: ['brakodelnya@gmail.com'],
    keyColor: 'rgb(204, 89, 51)',
    keyUnderlineColor: 'rgba(204, 89, 51, .14)',
    bgColor: '#e6e6f0',
    weekDay: 'sa',
  },
  'Владимир Колпаков': {
    authorBureauId: '27060',
    authorAvatarUrl: buildAuthorAvatarUrl('kolpakov.png'),
    authorArchiveUrl: 'kolpakov-chikin',
    authorCaption: 'Дизайнер, фотограф, автор гайдлайнов «Энви» и книги «Фотосъёмка автомобилей»',
    authorEmails: ['kolpakov367@gmail.com'],
    keyColor: 'rgb(48, 53, 207)',
    keyUnderlineColor: 'rgba(48, 53, 207, .14)',
    bgColor: '#ffa3a0',
    weekDay: 'sa',
  },
  'Максим Ильяхов': {
    authorBureauId: '626',
    authorAvatarUrl: buildAuthorAvatarUrl('ilyahov.png'),
    authorArchiveUrl: 'ilyahov',
    authorCaption: 'Главред, автор курса «Информационный стиль и редактура текста»',
    authorEmails: ['maxim@artgorbunov.ru', 'max@artgorbunov.ru'],
    keyColor: 'rgb(4, 80, 129)',
    keyUnderlineColor: 'rgba(4, 80, 129, .14)',
    bgColor: '#cbeffd',
    weekDay: 'su',
  },
  'Людмила Сарычева': {
    authorBureauId: '25030',
    authorAvatarUrl: buildAuthorAvatarUrl('sarycheva.png'),
    authorArchiveUrl: 'sarycheva',
    authorCaption: 'Редактор',
    authorEmails: ['svarila@kompotique.ru'],
    keyColor: 'rgb(4, 80, 129)',
    keyUnderlineColor: 'rgba(4, 80, 129, .14)',
    bgColor: '#cbeffd',
    weekDay: '',
  },
  'Антон Шнайдер': {
    authorBureauId: '24989',
    authorAvatarUrl: buildAuthorAvatarUrl('schnaider.png'),
    authorArchiveUrl: 'schnaider',
    authorCaption: 'Дизайнер, арт-директор',
    authorEmails: ['schnaider.anton@gmail.com'],
    keyColor: 'rgb(50, 29, 215)',
    keyUnderlineColor: 'rgba(50, 29, 215, .14)',
    bgColor: '#fffd38',
    weekDay: '',
  },
  'Родион Жабрев': {
    authorBureauId: '',
    authorAvatarUrl: buildAuthorAvatarUrl('zhabrev.png'),
    authorArchiveUrl: 'zhabrev',
    authorCaption: 'Ретушёр',
    authorEmails: ['to@rozhabrev.ru'],
    keyColor: 'rgb(176, 31, 20)',
    keyUnderlineColor: 'rgba(176, 31, 20, .14)',
    bgColor: '#e8ee6a',
    weekDay: '',
  },
  'Андрей Тиханов': {
    authorBureauId: '695',
    authorAvatarUrl: buildAuthorAvatarUrl('tichanov.png'),
    authorArchiveUrl: 'tihanov',
    authorCaption: 'Дизайнер',
    authorEmails: ['mail@andreytikhanov.com'],
    keyColor: 'rgb(177, 57, 60)',
    keyUnderlineColor: 'rgba(177, 57, 60, .14)',
    bgColor: '#fee2c4',
    weekDay: '',
  },
  'Евгений Лучинин': {
    authorBureauId: '1222',
    authorAvatarUrl: buildAuthorAvatarUrl('luchinin.png'),
    authorArchiveUrl: 'genue',
    authorCaption: 'Дизайнер',
    authorEmails: ['genue.luben@gmail.com'],
    keyColor: 'rgb(108, 56, 167)',
    keyUnderlineColor: 'rgba(108, 56, 167, .14)',
    bgColor: '#fee2f2',
    weekDay: '',
  },
  'Таня Мисютина': {
    authorBureauId: '698',
    authorAvatarUrl: buildAuthorAvatarUrl('misyutina.png'),
    authorArchiveUrl: 'misyutina',
    authorCaption: 'Создатель и руководитель Лаборатории данных, автор курса «Визуализация данных»',
    authorEmails: ['misyutina@gmail.com'],
    keyColor: 'rgb(239, 0, 93)',
    keyUnderlineColor: 'rgba(239, 0, 93, .14)',
    bgColor: '#fce5e9',
    weekDay: 'we',
  },
  'Владимир Беляев': {
    authorBureauId: '',
    authorAvatarUrl: buildAuthorAvatarUrl('belyaev.png'),
    authorArchiveUrl: 'belyaev',
    authorCaption: 'Юрист, предприниматель, оптимист, непофигист',
    authorEmails: ['nepofigist@ya.ru'],
    keyColor: 'rgb(8, 79, 130)',
    keyUnderlineColor: 'rgba(8, 79, 130, .14)',
    bgColor: '#d4e6fe',
    weekDay: '',
  },
  'Николай Товеровский': {
    authorBureauId: '669',
    authorAvatarUrl: buildAuthorAvatarUrl('toverovskiy.png'),
    authorArchiveUrl: 'kolan',
    authorCaption: 'Директор, автор курса «Управление проектами, людьми и собой»',
    authorEmails: ['kolan@artgorbunov.ru'],
    keyColor: 'rgb(16, 124, 124)',
    keyUnderlineColor: 'rgba(16, 124, 124, .14)',
    bgColor: '#c0fde4',
    weekDay: 'fr',
  },
  'Артём Поликарпов': {
    authorBureauId: '661',
    authorAvatarUrl: buildAuthorAvatarUrl('polikarpov.png'),
    authorArchiveUrl: 'polikarpov-mitin',
    authorCaption: 'Разработчик',
    authorEmails: ['artpolikarpov@artgorbunov.ru', 'artpolikarpov@gmail.com'],
    keyColor: 'rgb(8, 79, 130)',
    keyUnderlineColor: 'rgba(8, 79, 130, .14)',
    bgColor: '#daf4fd',
    weekDay: '',
  },
  'Коля Митин': {
    authorBureauId: '',
    authorAvatarUrl: buildAuthorAvatarUrl('mitin.png'),
    authorArchiveUrl: 'polikarpov-mitin',
    authorCaption: 'Разработчик',
    authorEmails: ['nick.mitin@artgorbunov.ru', 'nick.mitin@gmail.com'],
    keyColor: 'rgb(176, 31, 20)',
    keyUnderlineColor: 'rgba(176, 31, 20, .14)',
    bgColor: '#fee669',
    weekDay: '',
  },
  'Иван Богданов': {
    authorBureauId: '',
    authorAvatarUrl: buildAuthorAvatarUrl('bogdanov.png'),
    authorArchiveUrl: 'bogdanov',
    authorCaption: 'Дизайнер логотипов и фирменных стилей',
    authorEmails: ['welcome@bogdanovka.com'],
    keyColor: 'rgb(18, 103, 96)',
    keyUnderlineColor: 'rgba(18, 103, 96, .14)',
    bgColor: '#9dfee2',
    weekDay: '',
  },
  'Алексей Наумов': {
    authorBureauId: '',
    authorAvatarUrl: buildAuthorAvatarUrl('naumov.png'),
    authorArchiveUrl: 'naumov',
    authorCaption: 'Разработчик бюро',
    authorEmails: ['alexsnaumov@gmail.com'],
    keyColor: 'rgb(255, 73, 159)',
    keyUnderlineColor: 'rgba(255, 73, 159, .14)',
    bgColor: 'rgb(255, 224, 239)',
    weekDay: '',
  },
  'Юлия Бурдукова': {
    authorBureauId: '1398370',
    authorAvatarUrl: buildAuthorAvatarUrl('burdukova.png'),
    authorArchiveUrl: 'burdukova',
    authorCaption: 'Ведущий дизайнер в Контеке, выпускница Школы дизайнеров, в прошлом ретушёр бюро',
    authorEmails: ['ugochka@mail.ru'],
    keyColor: 'rgb(229, 46, 61)',
    keyUnderlineColor: 'rgba(229, 46, 61, .14)',
    bgColor: 'rgb(255, 220, 210)',
    weekDay: 'tu',
  },
}

const inflectIntoGenitiveCase = (author) => {
  if (author === 'Рецепты') return 'рецептов'

  const [firstname, lastname] = author.split(/\s/)
  const gender = (/(на|ва)$/.test(lastname)) ? 'female' : 'male'

  const genitiveCase = [
    petrovich[gender].first.genitive(firstname),
    petrovich[gender].last.genitive(lastname),
  ].join(' ')

  return genitiveCase
}

Object.keys(authors).forEach(author => {
  authors[author].coverBgColor = setAlpha(authors[author].bgColor, 0.5)
  authors[author].authorGenitiveName = inflectIntoGenitiveCase(author)
})

module.exports = authors
