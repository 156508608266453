var { getStoredEmail, storeEmail } = require('./emailUtils')
import { subscribeUser } from './emailUtils'

$(function() {
  'use strict'

  var CLASS_SUBSCRIBED = 'is__subscribed'
  var CLASS_ALREADY_SUBSCRIBED = 'is__alreadySubscribed'
  var CLASS_LOADING = 'is__loading'
  var CLASS_ERROR = 'has__error'
  var CLASS_INVALID_ID = 'has__invalidId'

  var MIN_LOADING_TIME = 300
  var DELAY_BEFORE_LOADING = 100
  var EMAIL_TEMPLATE = /\S+@\S+\.\S+/

  var loadingTimeout
  var loadingDelayTimeout
  var loadingStartTime

  var $document = $(document)
  var $subscriptionForms = $('.js__subscriptionForm')

  $subscriptionForms.each((__, el) => {
    var $form = $(el)

    var $input = $form.find('input')
    var $wrap = $form.closest('.js__subscriptionFormWrap')

    var $forms = $form.add($wrap)

    var EVENT_ID = 1
    var eventType = $form.attr('data-course-id')
    var subscriptionStatusId = 'event_subscribe_' + eventType + '_' + EVENT_ID

    if (!eventType) {
      $forms.addClass(CLASS_INVALID_ID)
      return
    }

    var syncAndReset = function() {
      var val = $(this).val()
      $input.not(this).val(val)

      $forms.removeClass(CLASS_ERROR)
      $forms.removeClass(CLASS_SUBSCRIBED)
    }

    var storedData = getStoredEmail(eventType)
    if (storedData.email) {
      $input.val(storedData.email)
      $forms.toggleClass(CLASS_SUBSCRIBED, storedData.isSubscribed)
      $forms.toggleClass(CLASS_ALREADY_SUBSCRIBED, storedData.isSubscribed)

      $document.trigger(`subscriptionChanged.${eventType}`, storedData.isSubscribed)
    }

    $form.on('click', (e) => e.stopPropagation())

    $form.on('submit', function(e) {
      e.preventDefault()

      var email = $input.val()

      if (!EMAIL_TEMPLATE.test(email)) {
        $forms.addClass(CLASS_ERROR)

        return false
      }

      loadingStartTime = false
      clearTimeout(loadingTimeout)
      clearTimeout(loadingDelayTimeout)

      loadingDelayTimeout = setTimeout(function() {
        $forms.addClass(CLASS_LOADING)
        loadingStartTime = new Date().getTime()
      }, DELAY_BEFORE_LOADING)

      $document.trigger(`submitSubscription.${eventType}`, { email })

      subscribeUser({ email, eventType }).then(() => {
        var preloaderDisplayDuration = loadingStartTime ? MIN_LOADING_TIME - (Date.now() - loadingStartTime) : 0

        clearTimeout(loadingDelayTimeout)
        loadingTimeout = setTimeout(function() {
          $forms.addClass(CLASS_SUBSCRIBED)
          $document.trigger(`subscriptionChanged.${eventType}`, true)
          $forms.removeClass(CLASS_LOADING)
        }, preloaderDisplayDuration)
      })

      return true
    })

    $input.on('input', syncAndReset)
  })
})
