module.exports = {
  sentence: { lastWordConnector: 'and' },

  dateFormats: {
    dateOnly: '%{month} %{day}',
    dateYearOnly: '%{month} %{day}, %{year}',
  },

  site: {
    name: 'Bureau Gorbunov',
    logoName: 'Bureau Gorbunov',
    footerCopyright: 'Bureau Gorbunov',
    host: 'bureau.rocks',
  },

  likely: {
    telegram: 'Send',
    facebook: 'Share',
    vkontakte: 'Share',
    pinterest: 'Pin',
    twitter: 'Tweet',
  },

  project: { bucketCaption: 'Recycled' },

  recycledLinks: {
    overview: 'Overview',
    recycled: '🗑 Recycled',
  },

  timeline: {
    start: 'Start',
    launch: 'Launch',
    launches: 'Launches',
    byPlan: ' planned',
    inFact: 'Launch',
  },

  defaultTotalTableHeading: 'Total',

  books: {
    spacerDiscount: {
      text: 'Special price for subscribers to other <a href="/books/">bureau’s books</a>',
      textMobile: 'Special price for subscribers to other <a href="/books/">bureau’s books</a>',
      months: 'for the first<br>three months',
    },
    spacerDemo: {
      title: 'Demo chapter',
      button: 'Try free',
    },
    bookSystemRequirements: {
      mainWithoutMobile: 'You will need a computer or tablet with internet connection and any modern browser',
      mainWithMobile: 'You will need a computer, tablet or phone with internet connection and any modern browser',
      retina: '<span class="bookSystemRequirements-eye">👁</span> We recommend using Retina displays to read the book, though it works on low‑end screens, too',
    },
    booksIntro: {
      lead: 'We publish web books on typography, user interface, text editing, project management, and other disciplines. Our books look beautiful and present knowledge in a delightful interactive form.',
      manifesto: 'About our book format<span class="device laptop desktop"> in the manifesto</span>',
      aside: 'Почему книги доступны только по подписке, сколько стоит подписка и как её отменить?',
      faq: 'Вопросы и ответы',
      devices: 'Читайте на компьютере и планшете',
      ownedBooks: 'Так на этой странице отмечаются книги, на которые вы подписаны',
      subscriptionFormExplanation: 'Оставьте почту, чтобы узнать о выходе книг и обновлениях других продуктов бюро.',
    },
    priceCaption_12: 'Minimal subscription is 1 year',
    priceCaptionRenewsAutomatically: 'Auto-renews',
    specialPriceCaptionRenewsAutomatically: 'Subscription auto-renews',
    discountPriceCaption: '<em>Special price</em> for Bureau Gorbunov Publishing subscribers',
    discountPriceCaptionPreorder: 'Preorder to get <em>extra 2 months free</em>',
    hasMobileVersionSticker: 'With a mobile version',
    stickerPreorderAndRead: 'Preorder and start reading',
    subscriptionFooter: {
      caption: 'Subscription auto-renews',
      captionCompact: 'Auto-renews',
      captionPreorder: 'Preorder to get <span class="is__highlighted">extra 2 months free.</span><br>Subscription auto-renews',
      subscription: 'Subscription',
      subscriptionForm: {
        title: 'What comes out when',
        placeholder: 'Email',
        caption: 'Subscribe to get updates about other new books.',
        captionPreorder: 'Subscribe to get updates about this and other new books.',
      },
      subscriptionButton: {
        caption: 'Subscription',
        captionPreorder: 'Preorder',
      },
    },
  },

  subscription: {
    subscription: 'Subscription',
    preorder: 'Preorder',
    unitsOneTime: '$',
    unitsMonthly: '$/month',
    defaultUnits_1: '$/year',
    defaultUnits_1Discount: '$',
    defaultUnits_12: '$',
    defaultUnits_12Discount: '$',
    subscriptionUnits_1: '$/year',
    subscriptionUnits_12: '$/year',
    schoolUnitsOneTime: '$',
    prices: {
      defaultLabel_1: 'The first year',
      defaultLabel_12: 'The first year',
      defaultLabel_12Discount: 'The first year',
      subscriptionLabel_12: 'Consecutive years',
    },
    subscribed: '✓ Subscribed',
    read: 'Read',
    subscriptionMarkLabel: {
      default: 'Yours',
      lastDay: 'day left',
      daysLeft: {
        one: 'day left',
        few: 'days left',
      },
    },
    retry: 'If the problem continues, <a href="mailto:%{email}">contact us</a> <a href="%{url}" class="button subscriptionRetry-link">Try Again</a>',
    updateCard: 'Update bank card',
  },

  emailForm: {
    placeholder: 'Email',
    authenticationEmailSent: 'A link to access your books has been sent to <b>%{email}</b>',
  },

  newPaybar: {
    emailNotices: {
      default: {
        self: 'Email address for accessing the book. If you’ve already subscribed to our books, use the same address<span class="device mobile">:</span>',
        gift: 'The email address of the payer. If you’ve paid for our books before, use the same address<span class="device mobile">:</span>',
      },
      book: {
        hasSubscription: 'You already have this book. A link to access the book has been sent to <b>%{email}</b>',
        hasSubscriptionCannotRead: 'You already have this book. We’ll send an email to <b>%{email}</b> when the book is out',
        hasBureausphere: 'A link to subscribe to the book has been sent to <b>%{email}</b>',
        hasBureausphereCannotRead: 'A link to subscribe to the book has been sent to <b>%{email}</b>',
      },
    },
    priceLabel: { book: { gift_12: 'For one year' } },
    devices: {
      book: {
        default: 'Computer<br class="device laptop desktop" /> and tablet',
        hasMobileVersion: 'Computer, tablet<br /> <span class="is__colored">and phone</span>',
      },
    },
    requirements: {
      book: {
        default: 'You will need a computer or tablet with internet connection and any modern browser. We recommend using Retina displays to read the book',
        hasMobileVersion: 'You will need a computer, tablet or phone with internet connection and any modern browser. We recommend using Retina displays to read the book',
      },
    },
    hasMobileVersion: {
      single: 'Now the book “%{bookTitle}” has a mobile version',
      many: 'Books with a mobile version are marked this way',
    },
    subscriptionStatus: {
      book: '<b>Next payment</b> <br class="device laptop desktop" /><span class="nowrap">$%{price}</span> on %{date}',
      gift: { book: 'Subscription is active <br class="device laptop desktop" /> <b>till %{date}</b>' },
    },
    cardIframe: {
      title: 'Pay by<span class="device desktop laptop"> bank</span> card',
      cvvHelp: 'CVC 2 / CVV 2 — three last digits on the back side',
      paytureHelp: 'The card data will be stored on Payture secure payment server. Bureau Gorbunov does not store your card data.',
    },
    anotherEmail: 'It’s not me',
    imNotUserName: 'It’s not me',
    changeCard: 'Update',
    newCardCaption: 'Previous bank card data will be automatically deleted',
    payerName: 'Full name',
    gift: {
      payerName: 'Your full name',
      presentee: 'Gift recipient',
      presenteeName: 'Name',
      presenteeEmail: 'Email',
    },
    submitButtonCaption: {
      book: {
        self: 'Your card will be charged. <br class="device laptop desktop">Bank fees on currency conversion may apply<a class="is__noUnderline newPaybar-help" href="/books/faq/#fees" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M8.757 15.286a1.127 1.127 0 0 0 1.05 1.029 1.271 1.271 0 0 0 1.239-1.155A1.115 1.115 0 0 0 10 14.089a1.294 1.294 0 0 0-1.239 1.2m-.8-9.807l.567.924a3.453 3.453 0 0 1 2.247-.987 1.33 1.33 0 0 1 1.512 1.428c0 1.407-2.1 2.184-2.814 3.129a3.271 3.271 0 0 0-.189 2.436h.987a2.71 2.71 0 0 1 .5-2.142c.8-.756 2.94-1.869 2.94-3.57 0-1.638-1.092-2.478-2.751-2.478a3.987 3.987 0 0 0-3 1.26" /><path d="M10.5 21A10.5 10.5 0 1 1 21 10.5 10.512 10.512 0 0 1 10.5 21zm0-20a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 10.5 1z" opacity=".5" class="semitransparent"/></svg></a>',
        selfWithPreorder: '<span class="device mobile">Preorder. </span>Your card will be charged. <br class="device laptop desktop">Bank fees on currency conversion may apply<a class="is__noUnderline newPaybar-help" href="/books/faq/#fees" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M8.757 15.286a1.127 1.127 0 0 0 1.05 1.029 1.271 1.271 0 0 0 1.239-1.155A1.115 1.115 0 0 0 10 14.089a1.294 1.294 0 0 0-1.239 1.2m-.8-9.807l.567.924a3.453 3.453 0 0 1 2.247-.987 1.33 1.33 0 0 1 1.512 1.428c0 1.407-2.1 2.184-2.814 3.129a3.271 3.271 0 0 0-.189 2.436h.987a2.71 2.71 0 0 1 .5-2.142c.8-.756 2.94-1.869 2.94-3.57 0-1.638-1.092-2.478-2.751-2.478a3.987 3.987 0 0 0-3 1.26" /><path d="M10.5 21A10.5 10.5 0 1 1 21 10.5 10.512 10.512 0 0 1 10.5 21zm0-20a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 10.5 1z" opacity=".5" class="semitransparent"/></svg></a>',
        gift: 'Your card will be charged. <br class="device laptop desktop">Bank fees on currency conversion may apply<a class="is__noUnderline newPaybar-help" href="/books/faq/#fees" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M8.757 15.286a1.127 1.127 0 0 0 1.05 1.029 1.271 1.271 0 0 0 1.239-1.155A1.115 1.115 0 0 0 10 14.089a1.294 1.294 0 0 0-1.239 1.2m-.8-9.807l.567.924a3.453 3.453 0 0 1 2.247-.987 1.33 1.33 0 0 1 1.512 1.428c0 1.407-2.1 2.184-2.814 3.129a3.271 3.271 0 0 0-.189 2.436h.987a2.71 2.71 0 0 1 .5-2.142c.8-.756 2.94-1.869 2.94-3.57 0-1.638-1.092-2.478-2.751-2.478a3.987 3.987 0 0 0-3 1.26" /><path d="M10.5 21A10.5 10.5 0 1 1 21 10.5 10.512 10.512 0 0 1 10.5 21zm0-20a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 10.5 1z" opacity=".5" class="semitransparent"/></svg></a>',
        giftWithPreorder: 'Preorder. Your card will be charged. Bank fees on currency conversion may apply<a class="is__noUnderline newPaybar-help" href="/books/faq/#fees" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M8.757 15.286a1.127 1.127 0 0 0 1.05 1.029 1.271 1.271 0 0 0 1.239-1.155A1.115 1.115 0 0 0 10 14.089a1.294 1.294 0 0 0-1.239 1.2m-.8-9.807l.567.924a3.453 3.453 0 0 1 2.247-.987 1.33 1.33 0 0 1 1.512 1.428c0 1.407-2.1 2.184-2.814 3.129a3.271 3.271 0 0 0-.189 2.436h.987a2.71 2.71 0 0 1 .5-2.142c.8-.756 2.94-1.869 2.94-3.57 0-1.638-1.092-2.478-2.751-2.478a3.987 3.987 0 0 0-3 1.26" /><path d="M10.5 21A10.5 10.5 0 1 1 21 10.5 10.512 10.512 0 0 1 10.5 21zm0-20a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 10.5 1z" opacity=".5" class="semitransparent"/></svg></a>',
      },
    },
    submitButtonText: {
      template: '<span class="device desktop laptop">%{text} </span>%{units}%{price}',
      book: {
        self: 'Complete order for',
        selfWithPreorder: 'Preorder for',
        gift: 'Give as a gift for',
        giftWithPreorder: 'Give as a gift for',
      },
    },
    giftButtonText: 'Gift',
    buyButtonText: {
      book: {
        default: 'Subscription',
        preorder: 'Preorder',
        subscribed: '✓ Subscription',
        subscribedPreorder: '✓ Preorder',
      },
    },
    moreButtonText: { book: 'Subscribe someone else...' },
    success: {
      book: {
        gift: '✓ Subscription complete',
        self: '✓ Subscribed',
      },
    },
    cancelled: 'Unsubscribed',
    successCaption: {
      book: 'The subscription is renewed <br class="device desktop laptop"/>automatically',
      bookWithPreorder: 'The subscription isn’t ticking yet <a class="is__noUnderline newPaybar-help" href="/books/faq/#ticking" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M8.757 15.286a1.127 1.127 0 0 0 1.05 1.029 1.271 1.271 0 0 0 1.239-1.155A1.115 1.115 0 0 0 10 14.089a1.294 1.294 0 0 0-1.239 1.2m-.8-9.807l.567.924a3.453 3.453 0 0 1 2.247-.987 1.33 1.33 0 0 1 1.512 1.428c0 1.407-2.1 2.184-2.814 3.129a3.271 3.271 0 0 0-.189 2.436h.987a2.71 2.71 0 0 1 .5-2.142c.8-.756 2.94-1.869 2.94-3.57 0-1.638-1.092-2.478-2.751-2.478a3.987 3.987 0 0 0-3 1.26" /><path d="M10.5 21A10.5 10.5 0 1 1 21 10.5 10.512 10.512 0 0 1 10.5 21zm0-20a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 10.5 1z" opacity=".5" class="semitransparent"/></svg></a> <br>We’ll send an email when <br>the book is out',
      bookWithPreorderCannotRead: 'The subscription isn’t ticking yet <a class="is__noUnderline newPaybar-help" href="/books/faq/#ticking" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M8.757 15.286a1.127 1.127 0 0 0 1.05 1.029 1.271 1.271 0 0 0 1.239-1.155A1.115 1.115 0 0 0 10 14.089a1.294 1.294 0 0 0-1.239 1.2m-.8-9.807l.567.924a3.453 3.453 0 0 1 2.247-.987 1.33 1.33 0 0 1 1.512 1.428c0 1.407-2.1 2.184-2.814 3.129a3.271 3.271 0 0 0-.189 2.436h.987a2.71 2.71 0 0 1 .5-2.142c.8-.756 2.94-1.869 2.94-3.57 0-1.638-1.092-2.478-2.751-2.478a3.987 3.987 0 0 0-3 1.26" /><path d="M10.5 21A10.5 10.5 0 1 1 21 10.5 10.512 10.512 0 0 1 10.5 21zm0-20a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 10.5 1z" opacity=".5" class="semitransparent"/></svg></a> <br>We’ll send an email when <br>the book is out',
    },
    tabs: {
      book: {
        self: 'For yourself',
        mobileSelf: 'For yourself',
        gift: 'As a gift',
      },
    },
    pricingInfo: {
      subscription_12: {
        book: {
          self: 'Minimal subscription is 1 year, auto-renews<span class="device mobile">.</span>',
          selfWithPreorder: 'Minimal subscription is 1 year, auto-renews. <br class="device laptop desktop" />Preorder to get <span class="is__colored">extra 2 months free<span class="device mobile">.</span></span>',
          gift: 'No further payments for you.<br/>The recipient will be able to use their own card to renew the subscription at the lowered rate of $%{price}/year',
          giftWithPreorder: 'No further payments for you.<br/>The recipient will be able to use their own card to renew the subscription at the lowered rate of $%{price}/year',
        },
      },
      customPricingBottomCaption: '<span class="is__gift is__colored">2 months free</span> %{startText}. <br/>Subscription auto-renews<span class="device mobile">.</span>',
    },
    giftMessage: {
      book: {
        withDiscount: { subscription_12: 'No further payments for you.<br/>The recipient will be able to use their own card to renew the subscription at the lowered rate of $%{price}/year' },
        withoutDiscount: { subscription_12: 'No further payments for you.<br/>The recipient will be able to use their own card to renew the subscription at the price of $%{price}/year' },
      },
    },
    successScreen: {
      book: {
        self: {
          heading: 'Subscription complete',
          headingWithPreorder: 'Preorder complete',
          access: 'Read now',
          accessText: 'A link to access the book has been sent to <b>%{email}</b><br/>',
          accessTextCannotRead: 'We’ll send an email to <b>%{email}</b>,<br/>when the book is out',
          text: 'Your bank card has been charged',
        },
        gift: {
          heading: 'Gift subscription complete',
          headingWithPreorder: 'Gift subscription preorder complete',
          accessText: 'A link to access the book has been sent<br/>to <b>%{presenteeEmail}</b>',
          accessTextCannotRead: 'An email about the gift has been sent<br/>to <b>%{presenteeEmail}</b>',
          text: 'Your bank card has been charged',
        },
      },
      close: 'Close',
      lock: '🔓',
    },
    title: {
      book: {
        self: 'Subscription <span class="device mobile"><br />to the interactive handbook <small>%{productTitle}</small></span>',
        gift: 'Gift subscription <span class="device mobile"><br />to the interactive handbook <small>%{productTitle}</small></span>',
      },
    },
    links: {
      license: 'License and refunds',
      cancellation: 'Cancel subscription',
      cancellations: {
        bookshelf: 'https://docs.google.com/document/d/1pMHrNQ_4LGgxxTTI4cqPBKYfZgUiCOpcewEwonK2BxM',
        book: 'https://docs.google.com/document/d/1pMHrNQ_4LGgxxTTI4cqPBKYfZgUiCOpcewEwonK2BxM',
      },
      licenses: {
        bookshelf: 'https://docs.google.com/document/d/1IRlYgsmNbUxmFDd1s3io3Sij8S3d1MyB_1r1C_wNB6M',
        book: 'https://docs.google.com/document/d/1IRlYgsmNbUxmFDd1s3io3Sij8S3d1MyB_1r1C_wNB6M',
      },
      book: {
        license: 'License and refunds',
        cancellation: 'Cancel subscription',
      },
    },
    notice: {
      book: {
        emailSent: {
          text: 'A link to access the book has been sent to <b>%{email}</b>',
          textCannotRead: 'We’ll send an email to <b>%{email}</b> when the book is out',
          buttonText: 'Send again',
        },
      },

      paymentFailed: '<b>Payment failed.</b> Try again or enter another bank card data',
      paymentFailedActionable: '<b>Payment failed.</b> Try again or enter <a href="javascript:;">another bank card data</a>',
      paymentFailedAmountExceed: '<b>Insufficient funds.</b> Enter another bank card data',
      paymentFailedAmountExceedActionable: '<b>Insufficient funds.</b> Enter <a href="javascript:;">another bank card data</a>',
      paymentFailedForeignIssuerBlockedCard: '<b>International payment failed.</b> Please select <a href="/pay/international/%{paybarAccessToken}">international bank card payment</a>',
      paymentFailedForeignIssuerBlockedCardCompletely: '<b>International payment failed.</b> Enter another bank card data or <a href="mailto:%{productEmail}">contact us to fix the problem</a>',
      subscriptionCancellationFailed: '<b>Failed to cancel subscription.</b> Try again. If the problem continues, contact us: <a href="mailto:books@bureau.rocks" style="text-decoration: none">books@bureau.rocks</a>',
      subscriptionCancellationRevertFailed: '<b>Failed to restore subscription.</b> Try again. If the problem continues, contact us: <a href="mailto:books@bureau.rocks" style="text-decoration: none">books@bureau.rocks</a>',
    },
    userCardNumber: 'Bank card',
    cardSwitch: {
      heading: 'Pay by bank card',
      existingCard: '%{userCardNumber}',
      card: 'Russian',
      internationalCard: 'International',
      newCard: 'New russian',
      newInternationalCard: 'New international',
    },
    launchpad: {
      book: 'Read now',
      bookNoAccess: 'Subscription',
    },
    specialPriceLabel: 'Low for subscribers',
    ribbonNotReady: 'Soon',
  },

  product: {
    email: {
      lecture: 'lectures@bureau.rocks',
      collecture: 'lectures@bureau.rocks',
      book: 'books@bureau.rocks',
      bookshelf: 'books@bureau.rocks',
      prep: 'school@bureau.rocks',
      school: 'school@bureau.rocks',
      course: 'courses@bureau.rocks',
    },
  },

  userPopup: {
    loginToBureausphere: 'Sign in',
    card: {
      default: 'Bank card',
      current: 'Bank card %{cardNumber}',
      missing: '<span>Update <span class="is__hiddenOnNarrowMobile">bank </span>card</span>',
    },
  },

  publishingSubscriptionForm: {
    heading: 'What comes out when',
    caption: 'Subscribe to get updates about other new books.',
    captionPreorder: 'Subscribe to get updates about this and other new books.',
  },

  realtimeCountdown: {
    days: {
      one: 'day',
      few: 'days',
      many: 'days',
      other: 'days',
    },
    hours: {
      one: 'hour',
      few: 'hours',
      many: 'hours',
      other: 'hours',
    },
    minutes: {
      one: 'minute',
      few: 'minutes',
      many: 'minutes',
      other: 'minutes',
    },
    seconds: {
      one: 'second',
      few: 'seconds',
      many: 'seconds',
      other: 'seconds',
    },
    short: {
      days: 'd',
      hours: 'h',
      minutes: 'm',
      seconds: 's',
    },
  },
  leadMagnet: {
    openLink: 'Open for free',
    downloadFile: 'Download for free',
  },

  subscriptionCancel: {
    validUntil: {
      book: 'The subscription to the book paid until %{validUntil}',
      bookPreorder: 'The subscription to the book is paid for 1 year and 2 months. The subscription will start ticking closer to the book\'s full release',
    },
    disclaimer: { book: 'If you cancel the subscription now, the book will be no longer accessible. The lowered rate after the first year is available only with an uninterrupted subscription.' },
    buttonKeep: 'Keep<span class="device laptop desktop"> subscription</span>',
    buttonKeepCaption: 'And use the paid period to the full',
    buttonCancel: 'Cancel<span class="device laptop desktop"> subscription</span>',
    buttonCancelCaption: { book: 'And immediately disable access to the book' },
  },

  lastChance: {
    subscriptionCancelled: { book: 'Book subscription has been cancelled' },
    motivator: {
      book: 'Restore your subscription and keep the paid period and the lowered subscription rate after the first year',
      bookNoTimeLeft: 'Restore your subscription and keep the lowered subscription rate after the first year',
    },
    buttonRevert: 'Restore subscription',
  },

  subscriptionNotifications: {
    renewMiss: 'Failed to renew subscription. Offer expired',
    renewEarlyMiss: 'Failed to renew subscription. Offer expired',
    renewFail: 'Payment failed. Try to follow the link in email again',
    renewEarlyFail: 'Payment failed. Try to follow the link in email again',
    renewSuccess: 'You have renewed your subscription. Thank you!',
    renewEarlySuccess: 'You have renewed your subscription. Thank you! Next payment due on %{subscriptionRenewsOn}',
    renewEarlyDouble: 'You have already renewed your subscription before. Next payment due on %{subscriptionRenewsOn}',
    redeemMiss: 'Could not open the book. Subscription has ended',
    redeemFail: 'Failed to activate the gift.',
    transferSuccess: 'Your subscription has been confirmed and will now renew automatically',
  },
}
