import { TextNode, Heading3 } from '../textNodes'
import BookSpacerLink from './bookSpacerLink'
import SubscriptionPricing from '../subscriptionPricing/subscriptionPricing'
import UpgradePricing from '../subscriptionPricing/upgradePricing'
import EmailForm from '../emailForm'
import isBookshelfAll from 'lib/isBookshelfAll'

import { LONG_LINK_LENGTH } from './bookSpacerUtils'

export const HalfWidthText = (props) => {
  const {
    productId,
    title,
    href,
    linkText,
    linkTextShort,
    prices,
    isProductBookshelf,
    hasSpecialOffer,
    onClick,
  } = props
  const hasLongLinkText = linkText.length >= LONG_LINK_LENGTH
  const isBookshelfWithSpecialOffer = isProductBookshelf && hasSpecialOffer

  return (
    <div className="bookSpacer-text device laptop desktop">
      <Heading3>
        { title }
      </Heading3>
      { isBookshelfWithSpecialOffer &&
        <UpgradePricing
          prices={ prices }
          unitsSize="medium"
          isBookshelfAll={ isBookshelfAll(productId) }
        />
      }

      { !hasSpecialOffer &&
        <SubscriptionPricing
          prices={ prices }
          unitsSize="medium"
        />
      }
      <BookSpacerLink
        href={ href }
        text={ hasLongLinkText && linkTextShort ? linkTextShort : linkText }
        onClick={ onClick }
      />
    </div>
  )
}

export const HalfWidthPreorderText = (props) => {
  const {
    title,
    email,
    href,
    linkText,
    linkTextShort,
    isSubscribed,
    onClick,
    onSubmit,
  } = props
  const hasLongLinkText = linkText.length >= LONG_LINK_LENGTH

  return (
    <div className="bookSpacer-text device laptop desktop">
      <Heading3>
        { title }
      </Heading3>
      <TextNode>
        { I18n.t('books.bookSpacer.subscriptionFormCaption') + ' ' }
        <BookSpacerLink
          href={ href }
          text={ hasLongLinkText && linkTextShort ? linkTextShort : linkText }
          onClick={ onClick }
          isInline
        />
      </TextNode>
      <EmailForm
        email={ email }
        onSubmit={ onSubmit }
        className="bookSpacer-form"
        isSubscribed={ isSubscribed }
        thinButton
      />
    </div>
  )
}
