import { Component } from 'preact'
import { connect } from 'preact-redux'
import cx from 'classnames'
import { formatPrice } from 'lib/textHelper'
import reachGoal from 'lib/reachGoal'

import { FormSubmitButton } from '../form'

class NewPaybarSubmitButton extends Component {
  constructor(props) {
    super(props)

    this.submit = this.submit.bind(this)
  }

  get goal() {
    const suffix = (this.props.type === 'gift') ? '-AS-GIFT' : ''

    return `${this.props.goalPrefix}_PAYBAR_${this.props.paybarGoalVersion}BUY${suffix}`
  }

  get price() {
    const { type, prices, paymentType, isStudent } = this.props

    const participantPrices = isStudent[type] ? prices.student : prices

    if (type === 'upgrade') return participantPrices.specialOffer.oneTime

    if (type === 'company') return participantPrices.companyOneTime

    const paymentTypePrices = paymentType === 'oneTime' ? participantPrices : participantPrices.monthly

    return paymentTypePrices.oneTime
  }

  get isPayByInvoice() {
    const { type, companyPaymentType } = this.props

    return type === 'company' && companyPaymentType === 'invoice'
  }

  get text() {
    const { type, product, participantType, isPreorder } = this.props
    const suffix = isPreorder ? 'WithPreorder' : ''
    const isForOtherParticipant = type === 'company' && participantType === 'other'
    const participantSuffix = isForOtherParticipant ? 'Other' : ''

    if (this.isPayByInvoice) {
      return I18n.t(`newPaybar.submitButtonText.${product}.payByInvoice${participantSuffix}`)
    }

    return I18n.t(`newPaybar.submitButtonText.${product}.${type}${suffix}${participantSuffix}`)
  }

  get buttonText() {
    const { text, price, isPayByInvoice } = this
    const units = I18n.t('subscription.unitsOneTime')
    const templateSuffix = isPayByInvoice ? 'ForPayByInvoice' : ''

    return I18n.t(`newPaybar.submitButtonText.template${templateSuffix}`, { text, price: formatPrice(price), units })
  }

  submit() {
    this.props.onSubmit()
    this.trackClick()
  }

  trackClick() {
    reachGoal(this.goal)
  }

  render(props) {
    const isButtonDisabled = !props.isValid || props.isLoading
    const className = cx('newPaybarSubmitButton', `is__${props.type}`)

    return (
      <span className="newPaybarSubmitButton-wrap">
        <FormSubmitButton
          className={ className }
          disabled={ isButtonDisabled }
          onClick={ this.submit }
          text={ this.buttonText } />
      </span>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    type: newPaybar.type,
    product: newPaybar.product,
    productId: newPaybar.productId,
    goalPrefix: newPaybar.goalPrefix,
    screen: newPaybar.screen,
    prices: newPaybar.prices,
    price: newPaybar.prices.oneTime,
    isLoading: newPaybar.isLoading,
    isValid: newPaybar.isValid,
    isPreorder: newPaybar.isPreorder,
    paybarGoalVersion: newPaybar.paybarGoalVersion,
    paymentType: newPaybar.paymentType,
    companyPaymentType: newPaybar.companyPaymentType,
    isStudent: newPaybar.isStudent,
    participantType: newPaybar.participantType,
  }
}

export default connect(mapStateToProps)(NewPaybarSubmitButton)
