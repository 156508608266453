import { Component } from 'preact'
import { connect } from 'preact-redux'

import { birmanize } from 'lib/textHelper'
import { moscowifyLocal } from '../calendar/timezoneUtils'

import NewPaybarFieldsetHeading from './newPaybarFieldsetHeading'

class NewPaybarCompanyHowToInfo extends Component {
  get howToCaption() {
    const { participantType, companyPaymentType, docFlowType, product } = this.props

    const resultText = I18n.t(
      `newPaybar.companyHowTo.${product}.${participantType}.${docFlowType}.${companyPaymentType}${this.invoiceSuffix}`,
      { date: this.invoiceLastDate }
    )

    return resultText
  }

  get invoiceSuffix() {
    if (this.props.companyPaymentType !== 'invoice') return ''

    const isNotEnoughTime = this.props.productDetails.missed_invoice_payment_deadline

    return isNotEnoughTime ? 'BeforeDeadline' : 'OnWorkdays'
  }

  get invoiceLastDate() {
    const deadline = this.props.product === 'school' ?
      this.props.dateOfNoReturn : this.props.productDetails.starts_at

    const date = moscowifyLocal(new Date(deadline))

    return birmanize(date, { format: 'fullMonthDateOnly' })
  }

  render() {
    return (
      <NewPaybarFieldsetHeading
        className="is__howTo"
        text={ I18n.t(`newPaybar.companyHowTo.${this.props.product}.title`) }
        caption={ this.howToCaption } />
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    productDetails: newPaybar.productDetails,
    companyPaymentType: newPaybar.companyPaymentType,
    type: newPaybar.type,
    participantType: newPaybar.participantType,
    docFlowType: newPaybar.docFlowType,
    product: newPaybar.product,
    dateOfNoReturn: newPaybar.dateOfNoReturn,
  }
}

export default connect(mapStateToProps)(NewPaybarCompanyHowToInfo)
