import Cookies from 'js-cookie'

import LeadMagnetWallBar from './leadMagnetWallBar'
import { subscribeUser } from '../subscriptionForm/emailUtils'
import { verifySubscription } from '../leadMagnet/leadMagnetUtils'
import reachGoal from 'lib/reachGoal'

const DEFAULT_MAILING_LIST = 'default'

export default class LeadMagnetWall {
  constructor(el, options = {}) {
    this.el = el
    this.options = options

    this.renderContents = this.renderContents.bind(this)
    this.reachGoal = this.reachGoal.bind(this)
    this.wallInContentsAndRenderLeadBar = this.wallInContentsAndRenderLeadBar.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  get meta() {
    return `&leadMagnetName=${this.options.name || ''}&leadMagnetId=${this.leadMagnetId}`
  }

  get email() {
    return (window.application.user?.email || Cookies.get('email') || '').toLowerCase()
  }

  get mailingList() {
    return this.options.mailingList || DEFAULT_MAILING_LIST
  }

  get leadMagnetId() {
    return this.options.id
  }

  checkSubscription() {
    const { email, mailingList, leadMagnetId } = this

    return verifySubscription({ email, mailingList, leadMagnetId })
  }

  render() {
    return this.checkSubscription()
      .then(this.renderContents)
      .catch(this.wallInContentsAndRenderLeadBar)
      .catch(e => console.error(e)) // eslint-disable-line no-console
  }

  renderContents() {
    this.el.outerHTML = this.el.innerHTML
  }

  reachGoal() {
    const goal = [
      'LEAD_MAGNET_WALL',
      (this.leadMagnetId + '').toUpperCase(),
      'SUBSCRIBED',
    ].filter(Boolean).join('_')

    reachGoal(goal)
  }

  wallInContentsAndRenderLeadBar() {
    this.paywallEmbeddedVideos()
    this.hideLikes()
    this.renderLeadMagnetBar()
  }

  paywallEmbeddedVideos() {
    if (this.options.mod?.includes('noWallAllVideos')) return

    const videos = document.querySelectorAll('.embeddedVideo')

    videos.forEach(video => {
      const paywalledImage = video.querySelector('.embeddedVideoPaywallCover')
      video.parentNode.replaceChild(paywalledImage, video)
    })
  }

  hideLikes() {
    if (this.options.mod?.includes('noLikes')) {
      document.querySelectorAll('.webpageLikes').forEach(likes => likes.remove())
    }
  }

  renderLeadMagnetBar() {
    const props = Object.assign({}, this.options, { onSubmit: this.onFormSubmit })

    $(this.el).reactify(LeadMagnetWallBar, props)
  }

  onFormSubmit({ email }) {
    return subscribeUser({
      email,
      eventType: this.mailingList,
      additionalMeta: this.meta,
    })
      .then(this.reachGoal)
      .then(() => window.location.reload())
      .catch((e) => console.error(e)) // eslint-disable-line no-console
  }
}
