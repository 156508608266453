import { connect } from 'preact-redux'
import cx from 'classnames'

import { Caption, TextNode } from '../../textNodes'
import { FormInput, FormCheckbox } from '../../form'

import NewPaybarFieldsetHeading from '../newPaybarFieldsetHeading'
import NewPaybarPersonalFieldsetBase from '../newPaybarPersonalFieldsetBase'
import NewPaybarParticipantTypeSwitch from '../newPaybarParticipantTypeSwitch'
import NewPaybarTelegramInput from '../newPaybarTelegramInput'
import NewPaybarPersonalFieldsetCompanySection from '../newPaybarPersonalFieldsetCompanySection'
import NewPaybarPersonalFieldsetGiftSection from '../newPaybarPersonalFieldsetGiftSection'

import { hasSelfSubscription, hasCompanySubscription } from '../helpers/companySubscriptionHelper'

import {
  addDirtyForm,
  removeDirtyForm,
  setFormValidity,
  setCompanyPaymentType,
  setParticipantType,
  setParticipantStudent,
} from '../newPaybarActions'
import I18n from 'i18n-js'

const SELF_VALIDITY_MAPS = ['payerName', 'tel']
const GIFT_VALIDITY_MAPS = ['payerName', 'presenteeName', 'presenteeEmail', 'presenteeTel']
const COMPANY_VALIDITY_MAPS = ['companyTitle', 'companyEmail', 'companyCEO']

class NewPaybarPersonalFieldset extends NewPaybarPersonalFieldsetBase {
  constructor(props) {
    super(props)

    this.validityStates = {
      payerName: !!this.props.userName,
      presenteeName: false,
      presenteeEmail: false,
      organization: !!this.props.userOrganization,
      presenteeOrganization: false,
      profession: !!this.props.userProfession,
      presenteeProfession: false,
      tel: false,
      telegram: false,
      presenteeTel: false,
      presenteeTelegram: false,
      companyTitle: false,
      companyEmail: false,
      companyCEO: false,
      instituteTitle: false,
      instituteTel: false,
      presenteeInstituteTitle: false,
      presenteeInstituteTel: false,
    }

    this.validityMaps = {
      self: ['profession', 'organization', ...SELF_VALIDITY_MAPS],
      gift: GIFT_VALIDITY_MAPS,
      company: [...SELF_VALIDITY_MAPS, ...COMPANY_VALIDITY_MAPS],
      companyOther: [...GIFT_VALIDITY_MAPS, ...COMPANY_VALIDITY_MAPS],
    }

    this.studentValidityMaps = {
      self: ['instituteTitle', 'instituteTel'],
      gift: ['presenteeInstituteTitle', 'presenteeInstituteTel'],
      company: ['instituteTitle', 'instituteTel'],
      companyOther: ['presenteeInstituteTitle', 'presenteeInstituteTel'],
    }

    this.rememberedHiddenInputs = ['participantType', 'companyPaymentType']

    this.handleParticipantStudentChange = this.handleParticipantStudentChange.bind(this)
  }

  get isValid() {
    const { hasTerms, type, participantType } = this.props

    if (hasTerms && !this.isAgreedToTerms) return false

    const key = (type === 'company' && participantType === 'other') ? 'companyOther' : type
    const validityMaps = [
      ...this.validityMaps[key],
      ...(this.isStudent ? this.studentValidityMaps[key] : []),
    ]

    return validityMaps.every(field => this.validityStates[field])
  }

  get isStudent() {
    return this.props.isStudent[this.props.type]
  }

  get hasSelfSubscription() {
    const { subscription } = this.props

    return hasSelfSubscription(subscription) || hasCompanySubscription(subscription)
  }

  componentDidUpdate() {
    this.props.setFormValidity({ isValid: this.isValid })
    this.rememberedHiddenInputs.forEach(input => this.remember(input))
    this.saveAgreedToTerms()
    this.saveIsStudent()
  }

  handleParticipantStudentChange({ value }) {
    this.props.setParticipantStudent(value, this.props.type)
  }

  render() {
    const isGift = this.props.type === 'gift'
    const isCompany = this.props.type === 'company'
    const isCompanyForOtherParticipant = isCompany &&
      (this.props.participantType === 'other' || this.hasSelfSubscription)

    return (
      <div className="newPaybarPersonalFieldset" ref={ this.bindEl }>
        { !isCompany && !isGift &&
          <NewPaybarFieldsetHeading
            text={ I18n.t(`newPaybar.course.participant.${this.props.type}`) } />
        }

        { !this.props.userName &&
          <div className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t(`newPaybar.course.name.${this.props.type}`) }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="payerName"
              type="text"
              autoComplete="name"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.payerName }
              ref={ ref => this.fieldRefs.payerName = ref } />
          </div>
        }

        { isCompany &&
          <div className="newPaybarPersonalFieldset-row is__switch">
            <NewPaybarParticipantTypeSwitch
              hasSelfSubscription={ this.hasSelfSubscription } />

            <input type="hidden" name="participantType" value={ this.props.participantType } />
          </div>
        }

        { !isGift && !isCompanyForOtherParticipant &&
          <label className="newPaybarPersonalFieldset-row has__inlineCaption">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.tel') }
            </TextNode>
            <div className="newPaybarPersonalFieldset-inputWithCaption">
              <FormInput
                className="newPaybarPersonalFieldset-input"
                name="tel"
                type="tel"
                required
                onInput={ this.onInput }
                onValidityChange={ this.onInputValidityChange }
                initialValue={ this.rememberedFields.tel || this.props.userTel }
                ref={ ref => this.fieldRefs.tel = ref } />
              <Caption html={ I18n.t('newPaybar.school.telCaption') } />
            </div>
          </label>
        }

        { !isGift && !isCompanyForOtherParticipant &&
          <NewPaybarTelegramInput
            name="telegram"
            onInput={ this.onInput }
            onValidityChange={ this.onInputValidityChange }
            initialValue={ this.rememberedFields.telegram || this.props.userTelegram }
            ref={ ref => this.fieldRefs.telegram = ref } />
        }

        { !isGift && !isCompanyForOtherParticipant && !this.props.userOrganization &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.course.organization') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="organization"
              key="organization"
              type="text"
              autoComplete="organization-title"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.organization }
              ref={ ref => this.fieldRefs.position = ref } />
          </label>
        }

        { !isGift && !isCompanyForOtherParticipant && !this.props.userProfession &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.profession') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="profession"
              type="text"
              autoComplete="organization-title"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.profession }
              ref={ ref => this.fieldRefs.profession = ref } />
          </label>
        }

        { (isGift || isCompanyForOtherParticipant) &&
          <NewPaybarPersonalFieldsetGiftSection
            rememberedFields={ this.rememberedFields }
            fieldRefs={ this.fieldRefs }
            onInput={ this.onInput }
            onInputValidityChange={ this.onInputValidityChange }
            hasCaption={ isCompanyForOtherParticipant }
            hasHeading={ !isCompany }
            hasTelegram
            hasTel
            hasFullPresenteeName />
        }

        { isCompanyForOtherParticipant &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.course.organization') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="presenteeOrganization"
              type="text"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.presenteeOrganization }
              ref={ ref => this.fieldRefs.presenteeOrganization = ref } />
          </label>
        }

        { isCompanyForOtherParticipant &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.profession') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="presenteeProfession"
              key="presenteeProfession"
              type="text"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.presenteeProfession }
              ref={ ref => this.fieldRefs.presenteeProfession = ref } />
          </label>
        }

        <div className="newPaybarPersonalFieldset-row is__checkbox">
          <FormCheckbox
            name="isStudent"
            value={ this.props.isStudent[this.props.type] }
            text={ I18n.t(`newPaybar.course.studentCheckbox.${this.props.type}`) }
            onChange={ this.handleParticipantStudentChange } />
        </div>

        { this.isStudent && !isGift && !isCompanyForOtherParticipant &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.course.instituteTitle') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="instituteTitle"
              type="text"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.instituteTitle }
              value={ this.rememberedFields.instituteTitle }
              ref={ ref => this.fieldRefs.instituteTitle = ref } />
          </label>
        }

        { this.isStudent && !isGift && !isCompanyForOtherParticipant &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.course.instituteTel') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="instituteTel"
              type="tel"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.instituteTel }
              value={ this.rememberedFields.instituteTel }
              ref={ ref => this.fieldRefs.instituteTel = ref } />
          </label>
        }

        { this.isStudent && (isGift || isCompanyForOtherParticipant) &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.course.instituteTitle') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="presenteeInstituteTitle"
              type="text"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.presenteeInstituteTitle }
              value={ this.rememberedFields.presenteeInstituteTitle }
              ref={ ref => this.fieldRefs.presenteeInstituteTitle = ref } />
          </label>
        }

        { this.isStudent && (isGift || isCompanyForOtherParticipant) &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.course.instituteTel') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="presenteeInstituteTel"
              type="tel"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.presenteeInstituteTel }
              value={ this.rememberedFields.presenteeInstituteTel }
              ref={ ref => this.fieldRefs.presenteeInstituteTel = ref } />
          </label>
        }

        { isCompany &&
          <NewPaybarPersonalFieldsetCompanySection
            rememberedFields={ this.rememberedFields }
            fieldRefs={ this.fieldRefs }
            companyPaymentType={ this.props.companyPaymentType }
            setCardCompanyPayment={ this.setCardCompanyPayment }
            setInvoiceCompanyPayment={ this.setInvoiceCompanyPayment }
            onInput={ this.onInput }
            onInputValidityChange={ this.onInputValidityChange } />
        }
      </div>
    )
  }
}

const mapDispatchToProps = {
  setFormValidity,
  addDirtyForm,
  removeDirtyForm,
  setCompanyPaymentType,
  setParticipantType,
  setParticipantStudent,
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    userName: newPaybar.userName,
    userProfession: newPaybar.userProfession,
    userOrganization: newPaybar.userOrganization,
    userTel: newPaybar.userTel,
    userTelegram: newPaybar.userTelegram,
    isVisible: newPaybar.isVisible,
    isPreorder: newPaybar.isPreorder,
    companyPaymentType: newPaybar.companyPaymentType,
    participantType: newPaybar.participantType,
    isStudent: newPaybar.isStudent,
    subscription: newPaybar.subscription,
    product: newPaybar.product,
    isAgreedToTerms: newPaybar.isAgreedToTerms,
    productDetails: newPaybar.productDetails,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPaybarPersonalFieldset)
