import JSON_HEADERS from 'jsonHeaders'
import throttle from 'throttle'

const serialize = JSON.stringify
const toBody = data => ({ body: serialize(data) })

const READ_TIMEOUT = 60 * 1000
const VIDEO_THROTTLE = 15 * 1000

class DocumentProgress {
  constructor() {
    if (!this.isTrackable) return

    this.track()
  }

  get isTrackable() {
    return !!$('meta[name="documentProgress"]').length && !!window.application.user
  }

  get pageId() {
    return window.application.pageId
  }

  get trackingParams() {
    return {
      method: 'POST',
      credentials: 'include',
      headers: JSON_HEADERS,
    }
  }

  get initialPayload() {
    return {
      subject: this.pageId,
      unit: 'percent',
      value: 0,
      max_value: 100,
    }
  }

  get readPayload() {
    return {
      subject: this.pageId,
      unit: 'percent',
      value: 100,
      max_value: 100,
    }
  }

  trackOpen() {
    fetch(
      '/document_progresses/',
      Object.assign({}, this.trackingParams, toBody(this.initialPayload))
    )
  }

  trackVideos() {
    document.addEventListener('videoProgressUpdate', throttle(VIDEO_THROTTLE, this.trackVideoProgress.bind(this)))
  }

  trackVideoProgress(e) {
    const { seconds, duration, id } = e.detail

    const payload = {
      subject: `${this.pageId}#${id}`,
      unit: 'seconds',
      value: seconds,
      max_value: duration,
    }

    fetch(
      '/document_progresses/',
      Object.assign({}, this.trackingParams, toBody(payload))
    )
  }

  trackRead() {
    fetch(
      '/document_progresses/',
      Object.assign({}, this.trackingParams, toBody(this.readPayload))
    )
  }

  track() {
    this.trackOpen()
    this.trackVideos()

    setTimeout(this.trackRead.bind(this), READ_TIMEOUT)
  }
}

module.exports = DocumentProgress
