$('.image.is__paywalled, .notFoundImage.is__paywalled, a.is__paywalled')
  .on('click', () => {
    const paywallFooter = document.getElementById('paywallFooter')
    const leadMagnetWallFooter = document.getElementById('leadMagnetWallFooter')
    const target = paywallFooter || leadMagnetWallFooter

    const pageTop = window.pageYOffset
    const { viewHeight } = window.application
    const paywallFooterTop = pageTop + target.getBoundingClientRect().top
    const noticeShift = paywallFooter ? 144 : 0
    const scrollTop = paywallFooterTop - viewHeight / 2 + noticeShift
    const duration = 300

    $('html, body')
      .animate({ scrollTop }, { duration })
  })
