import { Component } from 'preact'
import Cookies from 'js-cookie'
import { toSnakeCase, birmanize } from 'lib/textHelper'

import SubscriptionRetry from './subscriptionRetry'
import SubscriptionUpdateCard from './subscriptionUpdateCard'

class SubscriptionNotification extends Component {
  constructor(props) {
    super(props)

    this.state = { notifications: [] }
  }

  componentDidMount() {
    this.setState({ notifications: this.notificationsInCookiesOrUrl })
  }

  get notificationsInCookiesOrUrl() {
    return Object.keys(this.props.messages)
      .map(type => {
        const cookieLabel = `s_${toSnakeCase(type)}`
        const cookie = Cookies.get(cookieLabel)

        Cookies.remove(cookieLabel)

        return {
          type,
          message: this.props.messages[type],
          data: cookie,
        }
      })
      .filter(notification => {
        return notification.data !== undefined ||
          window.location.search.includes(notification.type)
      })
  }

  get productId() {
    return window.productSubscription?.subscription?.product_id
  }

  get subscriptionRenewsOn() {
    let subscription = window.productSubscription?.subscription

    if (window.location.search.includes('s_renewEarly') && !subscription?.next_payment) {
      subscription = { next_payment: Date.now() }
    }

    if (!subscription) return null

    return birmanize(new Date(subscription.next_payment), { format: 'dateYearOnly' })
  }

  get interpolationVars() {
    return { subscriptionRenewsOn: this.subscriptionRenewsOn }
  }

  isRetryVisible(notification) {
    return (this.productId && notification.type === 'redeemFail')
  }

  isUpdateCardVisible({ type }) {
    return ['renewFail', 'renewEarlyFail'].includes(type)
  }

  render() {
    const { notifications } = this.state

    return (
      <div className="subscriptionNotifications">
        {notifications.map(notification => (
          <div className="topLevelNotification holder is__active">
            <div className="topLevelNotification-main">
              <span className="topLevelNotification-text">
                { I18n.interpolate(notification.message, this.interpolationVars) }
              </span>

              {this.isRetryVisible(notification) &&
                <SubscriptionRetry
                  productId={this.productId}
                  url={`/subscriptions/${this.productId}/redeem/${notification.data}`}
                />
              }
            </div>

            <div className="topLevelNotification-side">
              { this.isUpdateCardVisible(notification) &&
                <SubscriptionUpdateCard />
              }
            </div>
          </div>
        ))}
      </div>
    )
  }
}

$('.js__subscriptionNotification').each((_, el) => {
  const DEFAULT_MESSAGES = I18n.t('subscriptionNotifications')

  const messages = {
    ...DEFAULT_MESSAGES,
    ...JSON.parse(el.dataset.messages || JSON.stringify({})),
  }

  $(el).reactify(SubscriptionNotification, { messages })
})
