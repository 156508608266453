import { Component } from 'preact'
import { connect } from 'preact-redux'

import NewPaybarSubmitButton from '../newPaybarSubmitButton'
import NewPaybarButtonMore from '../newPaybarButtonMore'
import NewPaybarFooterSubscriptionMessage from '../newPaybarFooterSubscriptionMessage'
import NewPaybarFooterCaption from '../course/newPaybarFooterCaption'
import EmailForm from '../../emailForm'
import NewPaybarEmailFormCaption from '../newPaybarEmailFormCaption'
import expandScreenToFlags from '../expandScreenToFlags'

class NewPaybarFooterCourse extends Component {
  render(props) {
    return (
      <div className="newPaybarCta">
        { props.isInitialScreen &&
          <div className="newPaybarCta">
            <EmailForm
              onSubmit={ props.onEmailFormSubmit }
              inputRef={ props.emailFormInputRef } />

            <NewPaybarEmailFormCaption />
          </div>
        }

        { props.isPurchaseScreen &&
          <NewPaybarSubmitButton onSubmit={ props.onSubmit } />
        }

        { props.isSuccessScreen &&
          <NewPaybarButtonMore product={ props.product } />
        }

        { props.isSubscriptionScreen &&
          <NewPaybarFooterSubscriptionMessage product={ props.product } type={ props.type } />
        }

        { (props.isPurchaseScreen || props.isSubscriptionScreen) &&
          <NewPaybarFooterCaption {...props} />
        }
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    product: newPaybar.product,
    subscription: newPaybar.subscription,
    screen: newPaybar.screen,
    type: newPaybar.type,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
    productDetails: newPaybar.productDetails,
    companyPaymentType: newPaybar.companyPaymentType,
    ...expandScreenToFlags(newPaybar.screen),
  }
}

export default connect(mapStateToProps)(NewPaybarFooterCourse)
