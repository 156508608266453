import { TextNode } from '../textNodes'
import BookSpacerLink from './bookSpacerLink'
import SubscriptionPricing from '../subscriptionPricing/subscriptionPricing'
import UpgradePricing from '../subscriptionPricing/upgradePricing'
import EmailForm from '../emailForm'
import isBookshelfAll from 'lib/isBookshelfAll'

import {
  LONG_TITLE_LENGTH,
  VERY_LONG_TITLE_LENGTH,
  VERY_LONG_DESCRIPTION_LENGTH,
} from './bookSpacerUtils'

export const FullWidthText = (props) => {
  const {
    productId,
    title,
    description,
    descriptionShort,
    href,
    linkText,
    prices,
    isProductBookshelf,
    hasSpecialOffer,
    onClick,
  } = props
  const hasLongTitle = title.length >= LONG_TITLE_LENGTH
  const hasVeryLongTitle = title.length >= VERY_LONG_TITLE_LENGTH
  const isBookshelfWithSpecialOffer = isProductBookshelf && hasSpecialOffer
  const isVeryLongDescription = description.length >= VERY_LONG_DESCRIPTION_LENGTH

  const descriptionClasses = descriptionShort ? 'device desktop' : ''

  return (
    <div className="bookSpacer-text device laptop desktop">
      <div className="bookSpacer-cols">
        <div className="bookSpacer-left">
          <div className="bookSpacer-titleWrapper">
            <a href={ href } className="bookSpacer-title doubleHover">
              <b>{ title }</b>
              <br />
              <span className={ descriptionClasses }>{ description }</span>
              { descriptionShort &&
                <span className="device laptop" dangerouslySetInnerHTML={ { __html: descriptionShort } } />
              }
              { isVeryLongDescription &&
                <BookSpacerLink href={ href } text={ linkText } onClick={ onClick } isInline />
              }
            </a>
          </div>
          { !isVeryLongDescription &&
            <BookSpacerLink href={ href } text={ linkText } onClick={ onClick } />
          }
        </div>
        <div className="bookSpacer-right">
          { !hasVeryLongTitle &&
            <div className="device desktop">
              { isBookshelfWithSpecialOffer &&
                <UpgradePricing
                  prices={ prices }
                  size="pageTitle"
                  unitsSize="heading"
                  isBookshelfAll={ isBookshelfAll(productId) }
                />
              }

              { !hasSpecialOffer &&
                <SubscriptionPricing
                  prices={ prices }
                  size="pageTitle"
                  unitsSize="heading"
                />
              }
            </div>
          }

          { !hasLongTitle &&
            <div className="device laptop">
              { isBookshelfWithSpecialOffer &&
                <UpgradePricing
                  prices={ prices }
                  size="title"
                  unitsSize="medium"
                  isBookshelfAll={ isBookshelfAll(productId) }
                />
              }

              { !hasSpecialOffer &&
                <SubscriptionPricing
                  prices={ prices }
                  unitsSize="medium"
                />
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export const FullWidthPreorderText = (props) => {
  const {
    title,
    email,
    href,
    linkText,
    isSubscribed,
    onClick,
    onSubmit,
  } = props

  return (
    <div className="bookSpacer-text device laptop desktop">
      <div className="bookSpacer-titleWrapper">
        <a href={ href } className="bookSpacer-title doubleHover">
          <b>{ title }</b>
        </a>
      </div>
      <div className="bookSpacer-cols">
        <div className="bookSpacer-left">
          <TextNode>
            { I18n.t('books.bookSpacer.subscriptionFormCaption') }
            <br />
            <BookSpacerLink
              href={ href }
              text={ linkText }
              onClick={ onClick }
              isInline
            />
          </TextNode>
        </div>
        <div className="bookSpacer-right">
          <EmailForm
            email={ email }
            onSubmit={ onSubmit }
            className="bookSpacer-form"
            isSubscribed={ isSubscribed }
            thinButton
          />
        </div>
      </div>
    </div>
  )
}
