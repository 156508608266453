import LeadMagnetWall from './leadMagnetWall'

$('.js__leadMagnetWall').each((_, el) => {
  const $el = $(el)
  const options = Object.assign(
    {},
    window.leadMagnetWall?.defaults,
    JSON.parse($el.attr('data-options'))
  );

  (new LeadMagnetWall(el, options)).render()
})
