import { Component } from 'preact'
import { connect } from 'preact-redux'

import NewPaybarSubscriptionStatus from './newPaybarSubscriptionStatus'
import NewPaybarSubmitButton from '../newPaybarSubmitButton'
import NewPaybarFooterCaption from '../newPaybarFooterCaption'
import NewPaybarFooterSubscriptionMessage from '../newPaybarFooterSubscriptionMessage'
import NewPaybarButtonMore from '../newPaybarButtonMore'
import EmailForm from '../../emailForm'
import NewPaybarEmailFormCaption from '../newPaybarEmailFormCaption'
import expandScreenToFlags from '../expandScreenToFlags'
import NewPaybarButtonSave from '../newPaybarButtonSave'
import isSubscriptionCancelled from '../isSubscriptionCancelled'

class NewPaybarFooterBookshelf extends Component {
  render(props) {
    const isSelf = props.type === 'self'
    const isGift = isSelf && props.subscription.subscription_type === 'gift'
    const isSelfSubscription = isSelf && props.isSubscriptionScreen
    const isCancelledSubscription = isSubscriptionCancelled(props.subscription)
    const isGiftedLecture = isGift && props.product === 'lecture'

    const shouldDisplaySubscriptionCaption = props.isPurchaseScreen ||
      (isSelfSubscription && !isCancelledSubscription && !isGift)

    const shouldDisplaySubscriptionStatus = !isCancelledSubscription &&
      isSelf &&
      (props.isSubscriptionScreen || props.isSuccessScreen)

    return (
      <div className="newPaybarCta">
        { props.isInitialScreen &&
          <div className="newPaybarCta">
            <EmailForm
              onSubmit={ props.onEmailFormSubmit }
              inputRef={ props.emailFormInputRef } />

            <NewPaybarEmailFormCaption />
          </div>
        }

        { props.isPurchaseScreen && !isGift &&
          <NewPaybarSubmitButton onSubmit={ props.onSubmit } />
        }

        { props.isSuccessScreen &&
          <NewPaybarButtonMore product={ props.product } />
        }

        { (props.isSubscriptionScreen || isGift) &&
          <NewPaybarFooterSubscriptionMessage
            product={ props.product }
            type={ props.type }
            isCancelled={ isCancelledSubscription } />
        }

        { shouldDisplaySubscriptionCaption &&
          <NewPaybarFooterCaption />
        }

        { shouldDisplaySubscriptionStatus &&
          <NewPaybarSubscriptionStatus
            className={ isGiftedLecture ? '' : 'device device__mobile'} />
        }

        { props.type === 'company' && props.isSubscriptionScreen &&
          <NewPaybarButtonSave onSubscriptionUpdate={ props.onSubscriptionUpdate } />
        }
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    product: newPaybar.product,
    subscription: newPaybar.subscription,
    screen: newPaybar.screen,
    type: newPaybar.type,
    cannotRead: newPaybar.cannotRead,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
    ...expandScreenToFlags(newPaybar.screen),
  }
}

export default connect(mapStateToProps)(NewPaybarFooterBookshelf)
